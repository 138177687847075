import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Seller',
    components: {
        AuctionInfo: require('./AuctionInfo/index.vue').default,
        AuctionStatus: require('../AuctionStatus/index.vue').default,
    },
    methods: {
        ...mapMutations({ loggerData: 'auction/loggerData' }),
    },
    computed: {
        ...mapGetters({
            preData: 'auction/preData',
        }),
        manager() {
            return this.preData.manager;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Менеджер Аукціону': 'Менеджер Аукціону',
                'Зараз на сайті': 'Зараз на сайті',
                'показати': 'показати',
                'Дмитро': 'Дмитро',
            },
            ru: {
                'Менеджер Аукціону': 'Менеджер Аукциона',
                'Зараз на сайті': 'Сейчас на сайте',
                'показати': 'показать',
                'Дмитро': 'Дмитрий',
            }
        }
    }
}
