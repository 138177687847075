export default {
    name: 'WaitingFor',
    i18n: {
        messages: {
            ru: {
                'Ожидается результат проверки на СТО': 'Ожидается результат проверки на СТО',
                'Диагностика ходовой части': 'Диагностика ходовой части',
                'Осмотр двигателя': 'Осмотр двигателя',
                'Проверка кузова авто': 'Проверка кузова авто',
                'Проверка салона и оборудования в нём': 'Проверка салона и оборудования в нём',
                'Проверка внешних световых приборов': 'Проверка внешних световых приборов',
                'Тестовая поездка': 'Тестовая поездка',
                'Компьютерная диагностика': 'Компьютерная диагностика',
                'Фотоотчет': 'Фотоотчет',
            },
            uk: {
                'Ожидается результат проверки на СТО': 'Очікується результат перевірки на СТО',
                'Диагностика ходовой части': 'Діагностика ходової частини',
                'Осмотр двигателя': 'Огляд двигуна',
                'Проверка кузова авто': 'Перевірка кузова авто',
                'Проверка салона и оборудования в нём': 'Перевірка салону і обладнання в ньому',
                'Проверка внешних световых приборов': 'Перевірка зовнішніх світлових приладів',
                'Тестовая поездка': 'Тестова поїздка',
                'Компьютерная диагностика': 'Комп\'ютерна діагностика',
                'Фотоотчет': 'Фотозвіт',
            }
        }
    }
}
