import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'Report',
    props: {
        id: Number,
        hash: String,
    },
    components: {
        Gallery: require('./Gallery/index.vue').default,
        Body: require('./Body/index.vue').default,
        RunningDiagnostics: require('./RunningDiagnostics/index.vue').default,
        ComputerDiagnostics: require('./ComputerDiagnostics/index.vue').default,
        SwitchBox: require('./SwitchBox/index.vue').default,
        CheckingLight: require('./CheckingLight/index.vue').default,
    },
    computed: {
        ...mapGetters({
            report: 'auction/techCheck/report',
            autoId: 'auction/id',
            isAuctionActive: 'auction/isAuctionActive',
        }),
        sto() {
            return this.report.sto;
        },
        photos() {
            return this.report.photos || [];
        },
        blocks() {
            return this.report.blocks || {};
        }
    },
    methods: {
        ...mapActions({ fetchReport: 'auction/techCheck/fetchReport' }),
    },
    i18n: {
        messages: {
            ru: {
                'Отчет проверки СТО': 'Отчет проверки СТО',
                'Проверено AUTO.RIA с помощью партнеров': 'Проверено AUTO.RIA с помощью партнеров',
                'Фотоотчет повреждений от СТО': 'Фотоотчет повреждений от СТО',
                'Смотреть отчет проверки СТО': 'Смотреть отчет проверки СТО',
                'Проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Проверенный партнер отметил детали, которые требуют дополнительного внимания',
                'Есть повреждения': 'Есть повреждения',
                'Предложите вашу цену': 'Предложите вашу цену',
                'По окончанию аукциона у вас будет возможность': 'По окончанию аукциона у вас будет возможность',
                'осмотреть авто вживую': 'осмотреть авто вживую',
                'и договориться о покупке непосредственно с продавцом': 'и договориться о покупке непосредственно с продавцом',
            },
            uk: {
                'Отчет проверки СТО': 'Звіт перевірки СТО',
                'Проверено AUTO.RIA с помощью партнеров': 'Перевірено AUTO.RIA за допомогою партнерів',
                'Фотоотчет повреждений от СТО': 'Фотозвіт пошкоджень від СТО',
                'Смотреть отчет проверки СТО': 'Дивитися звіт перевірки СТО',
                'Проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Перевірений партнер відмітив деталі, що потребують додаткової уваги',
                'Есть повреждения': 'Є пошкодження',
                'Предложите вашу цену': 'Запропонуйте вашу ціну',
                'По окончанию аукциона у вас будет возможность': 'По завершенню аукціону у вас буде можливість',
                'осмотреть авто вживую': 'оглянути авто вживу',
                'и договориться о покупке непосредственно с продавцом': 'і домовитись про купівлю безпосередньо із продавцем',
            }
        }
    }
};
