import {mapGetters} from 'vuex';

export default {
    name: 'Carvin',
    props: {
        data: Object,
    },
    computed: {
      ...mapGetters({
          preData: 'auction/preData'
      }),
    },
    i18n: {
        messages: {
            ru: {
                'Дата последней проверки': 'Дата последней проверки',
                'сверил данные по VIN-коду от продавца': 'сверил данные по VIN-коду от продавца',
                'Пробег': 'Пробег',
                'последний зафиксированный от': 'последний зафиксированный от',
                'Источник фиксации': 'источник фиксации',
                'один из ранее зафиксированных от': 'один из ранее зафиксированных от',
                'тыс.км': 'тыс.км',
                'Пробег проверен': 'Пробег проверен',
                'Расхождение': 'Расхождение',
                'составляет': 'составляет',
                'Детали': 'Детали',
                'Закрыть': 'Закрыть',
                'Пробег от продавца': 'Пробег от продавца',
                'информация отсутствует в базах данных': 'информация отсутствует в базах данных',
                'Данных о пробеге не обнаружено': 'Данных о пробеге не обнаружено',
                'Отображаются только официально зафиксированые случаи ДТП. По открытым базам данных Украины, стран Евросоюза, СНГ, США и Канады.': 'Отображаются только официально зафиксированые случаи ДТП. По открытым базам данных Украины, стран Евросоюза, СНГ, США и Канады.',
                'Проверено AUTO.RIA по 15 базам данных': 'Проверено AUTO.RIA по 15 базам данных',
                'Последний пробег, зафиксированный на СТО при сервисном центре, дилерском центре, при ремонте или техническом осмотре.': 'Последний пробег, зафиксированный на СТО при сервисном центре, дилерском центре, при ремонте или техническом осмотре.',
            },
            uk: {
                'Дата последней проверки': 'Дата останньої перевірки',
                'сверил данные по VIN-коду от продавца': 'звірив дані по VIN-коду від продавця',
                'Пробег': 'Пробіг',
                'последний зафиксированный от': 'останній зафіксований від',
                'Источник фиксации': 'джерело фіксації',
                'один из ранее зафиксированных от': 'один із раніше зафіксованих від',
                'тыс.км': 'тис.км',
                'Пробег проверен': 'Пробіг перевірено',
                'Пробег от продавца': 'Пробіг від продавця',
                'Расхождение': 'Розбіжність',
                'составляет': 'становить',
                'Детали': 'Деталі',
                'Закрыть': 'Закрити',
                'информация отсутствует в базах данных': 'інформація відсутня в базах даних',
                'Данных о пробеге не обнаружено': 'Даних про пробіг не виявлено',
                'Отображаются только официально зафиксированые случаи ДТП. По открытым базам данных Украины, стран Евросоюза, СНГ, США и Канады.': 'Відображаються тільки офіційно зафіксовані випадки ДТП. За відкритими базами даних України, країн Євросоюзу, СНД, США та Канади.',
                'Проверено AUTO.RIA по 15 базам данных': 'Перевірено AUTO.RIA за 15 базами даних',
                'Последний пробег, зафиксированный на СТО при сервисном центре, дилерском центре, при ремонте или техническом осмотре.': 'Останній пробіг, зафіксований на СТО при сервісному центрі, дилерському центрі, при ремонті чи технічному огляді.',
            },
        }
    }
}