import { mapGetters, mapMutations, mapActions } from 'vuex';
import socket from '../../../Socket';
const COOKIE_NAME = 'show_warning_on_make_bid';

const { get, set } = require('../../../../../helpers/cookie');

export default {
	name: 'ClosedBid',
	mixins: [require('../../../../../mixins/humanize')],
	data() {
		return {
			loginPopup: false,
			secondBid: false,
			confirmationPopup: false,
			price_: 0,
		};
	},
	computed: {
	    ...mapGetters({
			preData: 'auction/preData',
			bids: 'auction/bidsInfo',
			lotId: 'auction/lotId',
			payInfo: 'auction/payInfo',
		}),
		version() {
			return this.preData.version;
		},
		title() {
			const { preData: { brand, model, year } = {} } = {} = this;
			return `${brand.name} ${model.name} ${year}`;
		},
		id() {
	    	return this.preData.id;
		},
		startPrice() {
			const { auction: { prices: { start } } = {} } = {} = this.preData;
			return start;
		},
		price: {
	        get() {
				return this.price_ || this.bids.price || this.startPrice;
			},
			set(value) {
	            this.price_ = value;
			},
		},
		user() {
	        return this.preData.user;
		},
		bidName() {
			const { ABTests: { bidName } = {} } = this.preData || {};
			return Number(bidName) || 0;
		},
		averagePrice() {
			const { average: { price = 0 } = {}} = this.preData || {};
			return price ? this.humanizeNumber(price) : price;
		},
		humanizedPrice() {
	    	return this.humanizeNumber(this.price);
		}
	},
	methods: {
		...mapMutations({
			setPopup: 'auction/popup',
			setBidClosedAuction: 'auction/setBidClosedAuction',
			loggerData: 'auction/loggerData',
		}),
		showLoginPopup(clickAction) {
			this.setPopup(true);
			this.loginPopup = true;
			let dataToSlon = { click_action: clickAction, is_pop_up: 1 };
			this.loggerData(dataToSlon);
		},
		bid() {
			const { price, lotId, version } = this;
			this.loggerData({ click_action: 3, auction_rate: this.price, click_count: this.bids.count + 1 });
			socket.message({ action: 'makeBid', price, lotId, version });
			this.setBidClosedAuction(true);
			this.close();
		},
	    add() {
			const { user, bids } = this;
			if (!user.id) {
				this.showLoginPopup(1);
			} else if (!bids.bid) {
				this.price += this.payInfo.step;
			} else if (bids.bid) {
				this.setPopup(true);
				this.secondBid = true;
			}
		},
		subtract() {
            const { user, bids } = this;
            if (!user.id) {
				this.showLoginPopup(2);
            } else if (!bids.bid) {
            	if (this.price - this.payInfo.step >= this.startPrice) {
					this.price -= this.payInfo.step;
				}
			} else if (bids.bid) {
				this.setPopup(true);
            	this.secondBid = true;
			}
		},
		makeBid() {
			const { user, startPrice, price, bids, lotId } = this;
			if (!user.id) {
				this.showLoginPopup(3);
			} else if (!bids.bid && price >= startPrice) {
				this.setPopup(true);
				this.confirmationPopup = true;
			} else if (bids.bid) {
				this.setPopup(true);
				this.secondBid = true;
			}
		},
		close(clickAction) {
			if (clickAction) {
				this.loggerData({click_action: clickAction, is_pop_up: 1});
			}
	    	this.secondBid = false;
	    	this.loginPopup = false;
	    	this.confirmationPopup = false;
			this.setPopup(false);
		}
	},
	i18n: {
	    messages: {
	        ru: {
				'Предложите вашу цену': 'Предложите вашу цену',
				'Войти на AUTO.RIA': 'Войти на AUTO.RIA',
				'Закрыть': 'Закрыть',
				'от каждого участника': 'от каждого участника',
				'Принимается только 1 ставка': 'Принимается только 1 ставка',
				'Вы принимаете участие в закрытом аукционе': 'Вы принимаете участие в закрытом аукционе',
				'Изменить цену': 'Изменить цену',
				'Отменить': 'Отменить',
				'Подтвердить ставку': 'Подтвердить ставку',
				'Подтвердите вашу ставку': 'Подтвердите вашу ставку',
				'Ваша ставка принята': 'Ваша ставка принята',
				'Вы можете сделать 1 ставку в аукционе. Если ваша ставка окажется выигрышной – вы получите возможность встретиться с продавцом': 'Вы можете сделать 1 ставку в аукционе. Если ваша ставка окажется выигрышной – вы получите возможность встретиться с продавцом',
				'Отказаться': 'Отказаться',
				'Для участия в аукционе': 'Для участия в аукционе',
				'войдите или зарегистрируйтесь на AUTO.RIA': 'войдите или зарегистрируйтесь на AUTO.RIA',
				'Сделать ставку': 'Сделать ставку',
				'Предложить цену': 'Предложить цену',
				'средняя цена похожего авто': 'средняя цена похожего авто',
				'осмотреть авто вживую': 'осмотреть авто вживую',
				'и договориться о покупке непосредственно с продавцом': 'и договориться о покупке непосредственно с продавцом',
				'По окончанию аукциона у вас будет возможность': 'По окончанию аукциона у вас будет возможность',
	            tooltip: 'Вы можете сделать только 1 ставку. Определите сумму, которую вы готовы предложить за авто, и разместите вашу цену нажатием на кнопку "Сделать ставку". Делая ставку, вы подтверждаете готовность купить авто по указанной вами цене. 50 $ • минимальный шаг ставки',
			},
			uk: {
				'Предложите вашу цену': 'Запропонуйте вашу ціну',
				'Закрыть': 'Закрити',
				'от каждого участника': 'від кожного учасника',
				'Принимается только 1 ставка': 'Приймається лише 1 ставка',
				'Вы принимаете участие в закрытом аукционе': 'Ви берете участь у закритому аукціоні',
				'Отменить': 'Відмінити',
				'Изменить цену': 'Змінити ціну',
				'Подтвердить ставку': 'Підтвердити ставку',
				'Подтвердите вашу ставку': 'Підтвердіть вашу ставку',
				'Ваша ставка принята': 'Ваша ставка прийнята',
				'Вы можете сделать 1 ставку в аукционе. Если ваша ставка окажется выигрышной – вы получите возможность встретиться с продавцом': 'Ви можете зробити 1 ставку на аукціоні. Якщо ваша ставка виявиться виграшною - ви отримаєте можливість зустрітися з продавцем',
				'Отказаться': 'Відмовитися',
				'Войти на AUTO.RIA': 'Увійти на AUTO.RIA',
				'Для участия в аукционе': 'Для участі в аукціоні',
				'войдите или зарегистрируйтесь на AUTO.RIA': 'увійдіть або зареєструйтесь на AUTO.RIA',
				'Сделать ставку': 'Зробити ставку',
				'Предложить цену': 'Запропонувати ціну',
				'средняя цена похожего авто': 'середня ціна схожого авто',
				'осмотреть авто вживую': 'оглянути авто вживу',
				'и договориться о покупке непосредственно с продавцом': 'і домовитись про купівлю безпосередньо із продавцем',
				'По окончанию аукциона у вас будет возможность': 'По завершенню аукціону у вас буде можливість',
				tooltip: 'Ви можете зробити тільки 1 ставку. Визначте суму, яку ви готові запропонувати за авто, і розмістіть вашу ціну натисканням на кнопку "Зробити ставку". Роблячи ставку, ви підтверджуєте готовність купити авто за вказаною вами ціною. 50 $ • мінімальний крок ставки'
			},
		}
	}
};
