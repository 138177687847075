import {mapGetters} from 'vuex';

export default {
    name: 'Status',
    computed: {
        ...mapGetters({
            data: 'auction/preData',
        }),
        statusId(){
            const { auction: { status: { id } = {}, dates: { start, end } } = {} } = {} =  this.data;

            if (id === 5) {
                const now = Date.now();
                const _end = new Date(end).getTime();
                const _start = new Date(start).getTime();
                if (now < _start) {
                    return 4;
                } else if (now > _end ) {
                    return 6;
                }
            }

            return id;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Етапи продажу авто з аукціону': 'Етапи продажу авто з аукціону',
                'Заявка від продавця прийнята': 'Заявка від продавця прийнята',
                'Перевірка на СТО': 'Перевірка на СТО',
                'Технічний звіт готовий': 'Технічний звіт готовий',
                'Старт аукціону': 'Старт аукціону',
                'Аукціон завершено': 'Аукціон завершився',
                'Покупець оглядає авто': 'Покупець оглядає авто',
                'на зустрічі із продавцем': 'на зустрічі із продавцем',
                'Авто продано з аукціону': 'Авто продано з аукціону',
            },
            ru: {
                'Етапи продажу авто з аукціону': 'Этапы продажи авто с аукциона',
                'Заявка від продавця прийнята': 'Заявка от продавца принята',
                'Перевірка на СТО': 'Проверка на СТО',
                'Технічний звіт готовий': 'Технический отчет готов',
                'Старт аукціону': 'Старт аукциона',
                'Аукціон завершено': 'Аукцион завершен',
                'Покупець оглядає авто': 'Покупатель осматривает авто',
                'на зустрічі із продавцем': 'на встрече с продавцом',
                'Авто продано з аукціону': 'Авто продано с аукциона',
            }
        }
    }
};
