import { mapGetters} from 'vuex';
export default {
	name: 'CarFax',
	computed: {
		...mapGetters({
			preData: 'auction/preData',
		}),
		carfax() {
			return this.preData.carfaxCheck;
		}
	},
	i18n: {
		messages: {
			ru: {
				'Отчет про техническое состояние авто': 'Отчет про техническое состояние авто',
				'Доступна общая проверка истории авто в США': 'Доступна общая проверка истории авто в США',
				'Смотреть отчет': 'Смотреть отчет',
			},
			uk: {
				'Отчет про техническое состояние авто': 'Звіт про технічний стан авто',
				'Доступна общая проверка истории авто в США': 'Доступна загальна перевірка історії авто в США',
				'Смотреть отчет': 'Дивитися звіт',
			}
		}
	}
};
