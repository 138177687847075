import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
	name: 'Title',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			activeBookmark: 'auction/bookmarks/active',
		}),
		id() {
			return this.preData.id;
		},
	},
	mixins: [require('webpack:projectPath/vue/mixins/withData')],
	mounted() {
		const withSubscribe = Number(this.$route.query['with-subscribe']) || 0;
		this.getNotepadData()
			.then(items => typeof items === 'array' ? items : [])
			.then(items => items.filter(({ item_id = 0 } = {}) => item_id === this.id))
			.then(bookmarks => Boolean(bookmarks.length))
			.then((isBookmark) => {
				this.setActiveBookmark(isBookmark);
				if (!isBookmark && withSubscribe) {
					this.addBookmarks();
				}
			});
	},
	methods: {
		...mapMutations({
			setPopup: 'auction/popup',
			setActiveBookmark: 'auction/bookmarks/active',
		}),
		...mapActions({
			addBookmarks: 'auction/bookmarks/add',
			deleteBookmarks: 'auction/bookmarks/delete',
			isActiveBookmark: 'auction/bookmarks/isActive'
		}),
		subscribe(event) {
			event.preventDefault();
			event.stopPropagation();
			if (this.activeBookmark) {
				this.deleteBookmarks();
			} else {
				this.addBookmarks();
			}
		},
	}
};
