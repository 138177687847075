import { mapGetters } from 'vuex';

export default {
	name: 'Labels',
	components: {
		ListActiveAuctions: require('./ListActiveAuctions/index.vue').default,
		Description: require('./Description/index.vue').default,
		Label: require('./Label/index.vue').default,
	},
	computed: {
		...mapGetters({
			labels: 'auction/labels'
		}),
	}
};
