const RECONNECTION_TIME = 10000;

export default class {
	constructor(url) {
		this.url = url;
		this.ws = null;
		this.active = false;
		this.id = null;
		this.handlers = {};
		this.key = '';
		this.reconnectionTimeout = null;
	}

	init(id, key) {
		this.id = id;
		this.key = key;
		this.url = `${this.url}${id}`;
	}

	connect() {
		return this
			.getConnection()
			.then(() => {
				this.ws.send(JSON.stringify({ action: 'connect', sessionKey: this.key}));
			});
	}

	setHandler(action, func) {
	    if (!func || typeof func !== 'function') {
	        throw new Error('handler is not a function');
		}
	    this.handlers[action] = func;
	}
	message(message) {
		return this
			.getConnection()
			.then(ws => ws.send(JSON.stringify(message)));
	}

	getConnection() {
		if (!this.id || !this.key) {
			throw new Error('use init before');
		}
		return new Promise((resolve, reject) => {
			if (this.active && this.ws && this.ws.OPEN) { return resolve(this.ws); }
			const ws = new WebSocket(this.url);

			ws.onmessage = (event) => {
			    const { type, data } = event || {};
			    if (type === 'message') {
			        let parsed;
			        try {
						parsed = JSON.parse(data);
					} catch (error) {
			            console.warn('websocket Error => data is not json');
			            return;
					}
					if (!parsed.action) {
						console.warn('websocket warn =>', parsed, ' no action set');
						return;
					}
					if (typeof this.handlers[parsed.action] !== 'function') {
					    console.warn('websocket warn =>', parsed, 'is ignore, try to set handler');
					    return;
					}
					this.handlers[parsed.action](parsed);
				}
			};
			ws.onerror = (error) => {
				this.active = false;
				if(!this.reconnectionTimeout) {
					this.reconnectionTimeout = setTimeout(() => {
						this.connect();
						clearTimeout(this.reconnectionTimeout)
						this.reconnectionTimeout = null;
					}, RECONNECTION_TIME)
				}
			};
			ws.onclose = (event) => {
				this.active = false;
				if(!this.reconnectionTimeout) {
					this.reconnectionTimeout = setTimeout(() => {
						this.connect();
						clearTimeout(this.reconnectionTimeout);
						this.reconnectionTimeout = null;
					}, RECONNECTION_TIME)
				}
			};
			ws.onopen = (event) => {
				this.active = true;
				this.ws = ws;
				resolve(ws);
			};
		});
	}
}
