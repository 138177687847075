import Vue from 'vue';
import _laxios from '../_laxios';

const {auction: {techCheck} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		id: 0,
		report: {},
	}, techCheck),
	actions: {
		fetchReport({ commit, rootGetters }, { id, hash }) {
			const { 'lang/id': langId } = rootGetters;
			commit('setId', id);
			return _laxios
				.auctionReport
				.expandUrl({id})
				.request({
					params: { hash, langId },
					responseType: 'json',
				})
				.then(payload => {
					commit('report', payload);
					return payload;
				});

		}
	},
	mutations: {
		setId(state, id) {
			Vue.set(state, 'id', id);
		},
		report(state, report) {
			Vue.set(state, 'report', report);
		}
	},
	getters: {
		report({ report }) {
			return report;
		},
		id({ id }) {
			return id;
		}
	}
};
