import { mapGetters, mapMutations } from 'vuex';

export default {
	name: 'Preview',
	data() {
		return {
			showLimit: 8,
			showAll: false,
		};
	},
	computed: {
	    ...mapGetters({
			preData: 'auction/preData',
			title: 'auction/altTitle',
			cursor: 'auction/photos/cursor',
			photos: 'auction/photos/photos',
		}),
		items() {
	        return this.preData.photos;
		},
		count() {
			return this.photos.length;
		},
	},
	methods: {
		...mapMutations({
			setCursor: 'auction/photos/setCursor',
		}),
		changePhoto(index) {
			this.setCursor(index);
		}
	},
	i18n: {
		messages: {
			ru: {
				'Смотреть все': 'Смотреть все',
				'фотографий': 'фотографий',
				'Скрыть': 'Скрыть',
			},
			uk: {
				'Смотреть все': 'Дивитися всі',
				'фотографий': 'фотографій',
				'Скрыть': 'Приховати',
			}
		}
	}
};
