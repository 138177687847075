import { mapMutations } from 'vuex';

export default {
    name: 'ComputerDiagnostics',
    props: {
        data: Object,
    },
    data() {
        return {
            openBlock: false
        }
    },
    computed: {
        hasError() {
            return Boolean(this.data.elements.length);
        }
    },
    methods: {
        ...mapMutations({loggerData: 'auction/loggerData'})
    },
    i18n: {
        messages: {
            ru: {
                'Ошибок не обнаружено': 'Ошибок не обнаружено',
                'Компьютерная диагностика': 'Компьютерная диагностика',
            },
            uk: {
                'Ошибок не обнаружено': 'Помилок не виявлено',
                'Компьютерная диагностика': `Комп’ютерна діагностика`,
            }
        }
    }
}
