import { mapActions } from 'vuex';

const BASE = 'https://css.riastatic.com/images/demo/onboardingAuction/onboardingauction';

export default {
    name: 'OnBoarding',
    data() {
        return {
            step: 1,
        }
    },
    components: {
        Popup: require('../../Common/Popup.vue').default,
    },
    methods: {
        ...mapActions({
            update: 'auction/updateUser',
        }),
        close() {
            this.update({ onBoarding: Number(this.step) });
        },
        link(id) {
            return `${BASE}${this.langCode === 'ru' ? 'rus' : 'ukr'}${id || ''}`;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Зарегистрируйтесь или войдите': 'Зареєструйтесь або увійдіть',
                'для покупки авто': 'для купівлі авто',
                'Далее': 'Далі',
                'Сделать ставку': 'Зробити ставку',
                'Выиграйте аукцион': 'Виграйте аукціон',
                'и встретьтесь с продавцом': 'та зустріньтесь із продавцем',
                'Следите за ставками покупателей': 'Стежте за ставками покупців',
                'и принимайте участие в торгах': 'та беріть участь в торгах',
                'и сделайте свою — минимальный шаг 50$. Отменить ставку нельзя — только повысить': 'та зробіть свою — мінімальний крок 50$. Відмінити ставку не можна — тільки підвищити',
            },
            ru: {
                'Зарегистрируйтесь или войдите': 'Зарегистрируйтесь или войдите',
                'для покупки авто': 'для покупки авто',
                'Далее': 'Далее',
                'Сделать ставку': 'Сделать ставку',
                'Выиграйте аукцион': 'Выиграйте аукцион',
                'и встретьтесь с продавцом': 'и встретьтесь с продавцом',
                'и принимайте участие в торгах': 'и принимайте участие в торгах',
                'Следите за ставками покупателей': 'Следите за ставками покупателей',
                'и сделайте свою — минимальный шаг 50$. Отменить ставку нельзя — только повысить': 'и сделайте свою — минимальный шаг 50$. Отменить ставку нельзя — только повысить',
            },
        }
    }
}