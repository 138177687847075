import { mapGetters } from 'vuex';

export default {
	name: 'Main',
	components: {
		Photos: require('./Photos/index.vue').default,
		Autotest: require('./Autotest/index.vue').default,
		CarFax: require('./CarFax/index.vue').default,
		Labels: require('./Labels/index.vue').default,
		Verifications: require('./Verifications/index.vue').default,
		Auction: require('./../Auction/index.vue').default,
		AuctionOperation: require('./AuctionOperation/index.vue').default,
		auctionFinished: require('./../AuctionFinished/index.vue').default,
		auctionsActive: require('./../ActiveAuctions/index.vue').default,
		Price: require('./../Price/index.vue').default,
		Evaluation: require('./Evaluation/index.vue').default,
		Subscribe: require('../common/Subscribe/index.vue').default,
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			isAuctionActive: 'auction/isAuctionActive',
			soldOut: 'auction/soldOut',
			isPreActive: 'auction/isPreActive',
		}),
		auction() {
			return this.preData.auction;
		},
		techCheck() {
			return this.preData.techCheck;
		},
		status() {
			return this.auction.status.id;
		},
		race() {
			return this.preData.race;
		},
		verifications() {
			return this.preData.verifications;
		},
		carfax() {
			return this.preData.carfaxCheck;
		}
	},
	i18n: {
		messages: {
			ru: {
				'тыс. км пробег': 'тыс. км пробег',
				'Авто не в Украине': 'Авто не в Украине',
			},
			uk: {
				'тыс. км пробег': 'тис. км пробіг',
				'Авто не в Украине': 'Авто не в Україні',
			}
		}
	}
};
