import { mapMutations, mapGetters } from 'vuex';
import socket from '../../../Socket';
const COOKIE_NAME = 'show_warning_on_make_bid';
const { get, set } = require('../../../../../helpers/cookie');

export default {
    name: 'Bid',
    components: {
        Confirmation: require('./Confirmation/index.vue').default,
    },
    data() {
        return {
            price: 0,
            setByUser: false,
            pricePopup: false,
            loginPopup: false,
            dupBidPopup: false,
            highBid: false,
            confirmationPopup: false,
        };
    },
    mounted() {
        this.price = this.lastBid.price + this.step;
        socket.setHandler('ERR_DUP_BID', () => {
            this.dupBidPopup = true;
        });
        socket.setHandler('HIGH_BID', () => {
            this.highBid = true;
        });
    },
    computed: {
        ...mapGetters({
            id: 'auction/id',
            lastBid: 'auction/lastBid',
            lotId: 'auction/lotId',
            preData: 'auction/preData',
            payInfo: 'auction/payInfo',
            isAuctionActive: 'auction/isAuctionActive',
            soldOut: 'auction/soldOut',
            bids: 'auction/bidsInfo',
        }),
        title() {
            const { preData: { brand, model, year } = {} } = {} = this;
            return `${brand.name} ${model.name} ${year}`;
        },
        step() {
            return this.payInfo.step;
        },
        user() {
            return this.preData.user;
        },
        isAllowBid() {
            return this.payInfo.isAllowBid;
        },
        bidName() {
            const { ABTests: { bidName } = {} } = this.preData || {};
            return Number(bidName) || 0;
        }
    },
    methods: {
        ...mapMutations({
            setPopup: 'auction/popup',
            loggerData: 'auction/loggerData',
        }),
        bid() {
            const { price, lotId } = this;
            this.setByUser = false;
            this.loggerData({ click_action: 3, auction_rate: this.price, click_count: this.bids.length ? (this.bids.length + 1) : 1 });
            socket.message({action: 'makeBid', price, lotId});
            this.confirmationPopup = false;
        },
        showLoginPopup(clickAction) {
            this.setPopup(true);
            this.loginPopup = true;
            let dataToSlon = { click_action: clickAction, is_pop_up: 1 };
            this.loggerData(dataToSlon);
        },
        showConfirmationPopup() {
            this.confirmationPopup = true;
        },
        subtract() {
            if (!this.user.id) {
                this.showLoginPopup(2);
            } else if (this.lastBid.price < (this.price - this.step)) {
                this.price -= this.step;
                this.setByUser = true;
                this.loggerData({ click_action: 2, auction_rate: this.price });
            }
        },
        add() {
            if (!this.user.id) {
                this.showLoginPopup(1);
            } else {
                this.setByUser = true;
                this.price += this.step;
                this.loggerData({ click_action: 1, auction_rate: this.price });
            }
        },
        makeBid() {
            if (!this.user.id) {
                this.showLoginPopup(3);
            } else if (!this.open(3)) {
                this.showConfirmationPopup();
            }
        },
        open(clickAction = 0) {
            const show = !Number(get(COOKIE_NAME)) || !this.isAllowBid;
            if (show) {
                this.setPopup(true);
                this.pricePopup = true;
                if (clickAction) {
                    let dataToSlon = { click_action: clickAction, is_pop_up: 1 };
                    this.loggerData(dataToSlon);
                }
            }
            return show;
        },
        close(clickAction) {
            clickAction && this.loggerData({ click_action: clickAction, is_pop_up: 1 });
            this.setPopup(false);
            this.pricePopup = false;
            this.loginPopup = false;
            this.dupBidPopup = false;
            this.highBid = false;
            this.confirmationPopup = false;
        },
        allow() {
            set(COOKIE_NAME, 1);
            // this.bid();
            this.showConfirmationPopup();
            this.close();
        }
    },
    watch: {
        lastBid(value) {
            if (!this.setByUser || value.price >= this.price) {
                this.price = value.price + this.step;
                this.setByUser = false;
            }
        }
    },
    i18n: {
        messages: {
            ru: {
                'Вы готовы купить авто за указанную стоимость': 'Вы готовы купить авто за указанную стоимость',
                'tooltip': 'Определите сумму, которую вы готовы предложить за авто, и разместите вашу цену нажатием на кнопку "Сделать ставку". Делая ставку, вы подтверждаете готовность купить авто по указанной вами цене. 50 $ • минимальный шаг ставки',
                'Сделать ставку': 'Сделать ставку',
                'Да, все верно': 'Да, все верно',
                'Ваша ставка принята': 'Ваша ставка принята',
                'Закрыть': 'Закрыть',
                'Подтвердите ставку, чтобы она была сохранена': 'Подтвердите ставку, чтобы она была сохранена',
                'Да, уверен': 'Да, уверен',
                'Вы предлагаете за': 'Вы предлагаете за',
                'Предложите цену': 'Предложите цену',
                'Следующую ставку вы сможете сделать, когда эта будет перебита другим покупателем': 'Следующую ставку вы сможете сделать, когда эта будет перебита другим покупателем',
                'Готовы сделать ставку?': 'Готовы сделать ставку?',
                'Нажимая на кнопку': 'Нажимая на кнопку',
                'Вы соглашаетесь с': 'Вы соглашаетесь с',
                'Правилами аукциона': 'Правилами аукциона',
                'Согласиться': 'Согласиться',
                'средняя цена похожего авто': 'средняя цена похожего авто',
                'Для участия в аукционе': 'Для участия в аукционе',
                'войдите или зарегистрируйтесь на AUTO.RIA': 'войдите или зарегистрируйтесь на AUTO.RIA',
                'Войти на AUTO.RIA': 'Войти на AUTO.RIA',
                'Отказаться': 'Отказаться',
                'Отменить': 'Отменить',
                'Подтвердить': 'Подтвердить',
                'Пополнить счет': 'Пополнить счет',
                'Предложить цену': 'Предложить цену',
                'Предложите цену авто': 'Предложите цену авто',
                'Предложите цену авто, за которую вы готовы его приобрести. У вас будет возможность выиграть аукцион и встретиться с продавцом для осмотра авто перед покупкой': 'Предложите цену авто, за которую вы готовы его приобрести. У вас будет возможность выиграть аукцион и встретиться с продавцом для осмотра авто перед покупкой',
                'На вашем Личном счете должно быть не менее 50 грн. Эта сумма останется на счете и необходима для участия в торгах': 'На вашем Личном счете должно быть не менее 50 грн. Эта сумма останется на счете и необходима для участия в торгах',
                'По окончанию аукциона у вас будет возможность': 'По окончанию аукциона у вас будет возможность',
                'осмотреть авто вживую': 'осмотреть авто вживую',
                'и договориться о покупке непосредственно с продавцом': 'и договориться о покупке непосредственно с продавцом',
                'Ваша цена превышает максимально возможную для этого авто.': 'Ваша цена превышает максимально возможную для этого авто.',
                'Снизьте, пожалуйста, ставку': 'Снизьте, пожалуйста, ставку',
                'Снизьте ставку': 'Снизьте ставку',
                'Предложите вашу цену': 'Предложите вашу цену',
            },
            uk: {
                'Вы готовы купить авто за указанную стоимость': 'Ви готові купити авто за вказану вартість',
                'tooltip': 'Визначте суму, яку ви готові запропонувати за авто, і розмістіть вашу ціну натисканням на кнопку "Зробити ставку". Роблячи ставку, ви підтверджуєте готовність купити авто за вказаною вами ціною. 50 $ • мінімальний крок ставки',
                'Сделать ставку': 'Зробити ставку',
                'Да, все верно': 'Так, все вірно',
                'Ваша ставка принята': 'Ваша ставка прийнята',
                'Закрыть': 'Закрити',
                'Подтвердите ставку, чтобы она была сохранена': 'Підтвердіть ставку, щоб вона була збережена',
                'Да, уверен': 'Так, впевнений',
                'Вы предлагаете за': 'Ви пропонуєте за',
                'Предложите цену': 'Запропонувати ціну',
                'Следующую ставку вы сможете сделать, когда эта будет перебита другим покупателем': 'Наступну ставку ви зможете зробити, коли ця буде перебита іншим покупцем ',
                'Готовы сделать ставку?': 'Готові зробити ставку?',
                'Нажимая на кнопку': 'Натискаючи на кнопку',
                'Пополнить счет': 'Поповнити рахунок',
                'Вы соглашаетесь с': 'Ви погоджуєтеся з',
                'Правилами аукциона': 'Правилами аукціону',
                'войдите или зарегистрируйтесь на AUTO.RIA': 'увійдіть або зареєструйтесь на AUTO.RIA',
                'Согласиться': 'Погодитися',
                'средняя цена похожего авто': 'середня ціна схожого авто',
                'Для участия в аукционе': 'Для участі в аукціоні',
                'Войти на AUTO.RIA': 'Увійти на AUTO.RIA',
                'Отказаться': 'Відмовитися',
                'Отменить': 'Відмінити',
                'Подтвердить': 'Підтвердити',
                'Предложите цену авто': 'Запропонуйте ціну авто',
                'Предложить цену': 'Запропонувати ціну',
                'Предложите цену авто, за которую вы готовы его приобрести. У вас будет возможность выиграть аукцион и встретиться с продавцом для осмотра авто перед покупкой': 'Запропонуйте ціну авто, за яку ви готові його придбати. У вас буде можливість виграти аукціон та зустрітись з продавцем для огляду авто перед купівлею',
                'На вашем Личном счете должно быть не менее 50 грн. Эта сумма останется на счете и необходима для участия в торгах': 'На вашому Особистому рахунку повинно бути щонайменше 50 грн. Ця сума залишиться на рахунку й необхідна для участі в торгах',
                'По окончанию аукциона у вас будет возможность': 'По завершенню аукціону у вас буде можливість',
                'осмотреть авто вживую': 'оглянути авто вживу',
                'и договориться о покупке непосредственно с продавцом': 'і домовитись про купівлю безпосередньо із продавцем',
                'Ваша цена превышает максимально возможную для этого авто.': 'Ваша ціна перевищує максимально можливу для цього авто.',
                'Снизьте, пожалуйста, ставку': 'Знизьте, будь ласка, ставку',
                'Снизьте ставку': 'Знизьте ставку',
                'Предложите вашу цену': 'Запропонуйте вашу ціну',
            },
        }
    }
}
