<template>
  <div class="promo-auction">
    <h2 class="size16 bold">{{ $t('Беріть участь в аукціоні') }} AUTO.RIA</h2>
    <div class="mb-10">
      {{ $t('Більше 50 проданих з аукціону авто! Беріть участь в живих торгах і купуйте авто в новому форматі') }}
    </div>
    <a :href="`${langPrefix}/auction-rules/`" target="_blank" class="btn-blue text-c boxed" @click="loggerData({ click_action: 12 })">{{ $t('Правила аукціону') }}</a>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  name: 'AuctionInfo',
  methods: {
    ...mapMutations({ loggerData: 'auction/loggerData' }),
  },
  i18n: {
    messages: {
      ru: {
        'Беріть участь в аукціоні': 'Участвуйте в аукционе',
        'Більше 50 проданих з аукціону авто! Беріть участь в живих торгах і купуйте авто в новому форматі': 'Более 50 проданных с аукциона авто! Принимайте участие в живых торгах и покупайте авто в новом формате',
        'Правила аукціону': 'Правила аукциона',
      },
      uk: {
        'Беріть участь в аукціоні': 'Беріть участь в аукціоні',
        'Більше 50 проданих з аукціону авто! Беріть участь в живих торгах і купуйте авто в новому форматі': 'Більше 50 проданих з аукціону авто! Беріть участь в живих торгах і купуйте авто в новому форматі',
        'Правила аукціону': 'Правила аукціону',
      }
    }
  }
}
</script>
