export default {
	messages: {
		uk: {
			'Добавить объявление в избранное': 'Додати оголошення в избранное',
			'Аукцион': 'Аукціон',
		},
		ru: {
			'Добавить объявление в избранное': 'Добавить объявление в обране',
			'Аукцион': 'Аукцион',
		}
	}
};
