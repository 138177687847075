export default {
	name: 'Details',
	props: {
		carvin: Object,
	},
	computed: {
		isDamage() {
			return this.carvin?.damage?.status;
		},
	},
	i18n: {
		messages: {
			ru: {
				'Был в ДТП': 'Был в ДТП'
			},
			uk: {
				'Был в ДТП': 'Був в ДТП'
			}
		}
	}
};
