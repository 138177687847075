import { mapGetters, mapMutations } from 'vuex';

export default {
	name: 'AuctionOperation',
	components: {
		Timer: require('../../common/Timer/index.vue').default,
		Statistic: require('./Statistic/index.vue').default,
		BidsList: require('../../BidsList/index.vue').default,
		Prices: require('./Prices/index.vue').default,
		Price: require('../../Price/index.vue').default,
		Bid: require('./Bid/index.vue').default,
		ClosedBid: require('./ClosedBid/index.vue').default,
	},
	data() {
		return {
			showPopup: false,
			setByUser: false,
			bid: {},
		};
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			bidsInfo: 'auction/bidsInfo',
			lastBid: 'auction/lastBid',
			isAuctionActive: 'auction/isAuctionActive',
			soldOut: 'auction/soldOut',
		}),
		auction() {
			return this.preData.auction;
		},
		endDate() {
			return new Date(this.auction.dates.end).getTime();
		},
		soldOutPrice() {
			const { winningBid } = this.auction;
			return winningBid.price || this.lastBid.price;
		},
		soldOutDate() {
			const { winningBid } = this.auction;
			return moment(winningBid.date || this.lastBid.date).fromNow();
		},
		version() {
			return this.auction.version;
		},
		carfax() {
			return this.preData.carfaxCheck;
		}
	},
	methods: {
		...mapMutations({ loggerData: 'auction/loggerData' }),
		openPopup() {
			this.showPopup = true;
		},
		close() {
			this.showPopup = false;
		},
	},
	mounted() {
		this.bid = this.$refs.bid;
	},
	i18n: {
		messages: {
			ru: {
				'Аукцион': 'Аукцион',
				'Аукцион завершен': 'Аукцион завершен',
				'До конца аукциона осталось': 'До конца аукциона осталось',
				'Смотреть результат проверки на СТО': 'Смотреть результат проверки на СТО',
				'Смотреть отчет': 'Смотреть отчет',
				'tooltip': 'Определите сумму, которую вы готовы предложить за авто, и разместите вашу цену нажатием на кнопку "Сделать ставку". Делая ставку, вы подтверждаете готовность купить авто по указанной вами цене. 50 $ • минимальный шаг ставки',
			},
			uk: {
				'Аукцион': 'Аукціон',
				'Аукцион завершен': 'Аукціон заверше',
				'До конца аукциона осталось': 'До кінця аукціону залишилося',
				'Смотреть результат проверки на СТО': 'Дивитися результат перевірки на СТО',
				'Смотреть отчет': 'Дивитися звіт',
				'tooltip': 'Визначте суму, яку ви готові запропонувати за авто, і розмістіть вашу ціну натисканням на кнопку "Зробити ставку". Роблячи ставку, ви підтверджуєте готовність купити авто за вказаною вами ціною. 50 $ • мінімальний крок ставки',
			}
		}
	}
};
