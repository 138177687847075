import { mapGetters } from 'vuex';

export default {
	name: 'Breadcrumbs',
	computed: {
		...mapGetters({
			preData: 'auction/preData'
		}),
		state() {
			return this.preData.state;
		},
		brand() {
			return this.preData.brand;
		},
		city() {
			return this.preData.city;
		},
		model() {
			return this.preData.model;
		},
	},
	i18n: {
		messages: {
			ru: {
				'области': 'области',
				'Продажа авто': 'Продажа авто',
				'Продажа': 'Продажа',
				'лидер автомобильной интернет-торговли': 'лидер автомобильной интернет-торговли',
			},
			uk: {
				'области': 'області',
				'Продажа авто': 'Продаж авто',
				'Продажа': 'Продаж',
				'лидер автомобильной интернет-торговли': 'лідер автомобільної інтернет-торгівлі',
			}
		}
	}
};
