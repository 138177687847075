import { mapMutations, mapGetters } from 'vuex';

export default {
	name: 'MegaPhoto',
	data() {
		return {
			show: true,
		};
	},
	components: {
		Carusel: require('./Carusel/index.vue').default,
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			photos: 'auction/photos/photos',
		})
	},
	methods: {
		...mapMutations({
			setPopup: 'auction/popup',
			setShowMegaPhoto: 'auction/showMegaPhoto'
		}),
		close() {
			this.setShowMegaPhoto(false);
		}
	}
};
