import { mapGetters, mapMutations } from 'vuex';

import { AUTO_RIA_STATIONS } from '../../../../../../../constants/autotest';

export default {
  name: 'Body',
  props: {
    data: Object,
  },
  data() {
    return {
      showDetails: false,
      openBlock: false,
    };
  },
  computed: {
    ...mapGetters({
      autotestData: 'TechnicalCheck/autotestData',
    }),
    stationId() {
      return this.autotestData?.stoData?.station_id;
    },
    isAutoRiaStation() {
      return AUTO_RIA_STATIONS.includes(this.stationId);
    },
    replacementPoint() {
      return this.isAutoRiaStation ? this.$t('Сколи') : this.$t('Замена');
    },
    alignmentPoint() {
      return this.isAutoRiaStation ? this.$t('Подряпини') : this.$t('Рихтовка');
    },
    elements() {
      return this.data.elements || {};
    },
    moreBtnText() {
      return this.$t(this.showDetails ? 'Скрыть' : 'Смотреть все');
    },
  },
  methods: {
    ...mapMutations({ loggerData: 'auction/loggerData' }),
    hasDefect(id) {
      return this.elements.warning[id];
    },
    element(id) {
      const { elements: { warning = {}, origin = {} } = {} } = this;
      return warning[id] || origin[id] || {};
    },
    elementRepaired(id) {
      const {
        repainting, putty, replacement, rectify,
      } = this.element(id);
      return Boolean(repainting || putty || replacement || rectify);
    },
  },
  i18n: {
    messages: {
      ru: {
        'Смотреть все': 'Смотреть все',
        Скрыть: 'Скрыть',
        Шпатлевка: 'Шпатлевка',
        Замена: 'Замена',
        Перекраска: 'Перекраска',
        'Наличие следов кузовного ремонта': 'Наличие следов кузовного ремонта',
        Толщина: 'Толщина',
        Коррозия: 'Коррозия',
        'Шины и диски': 'Шины и диски',
        Остекление: 'Остекление',
        Комментарий: 'Комментарий',
        Итог: 'Итог',
        'Проверка кузова авто': 'Проверка кузова авто',
        'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания',
      },
      uk: {
        'Смотреть все': 'Дивитись все',
        Скрыть: 'Сховати',
        Шпатлевка: 'Шпаклівка',
        Замена: 'Заміна',
        Перекраска: 'Фарбування',
        'Наличие следов кузовного ремонта': 'Наявність слідів кузовного ремонту',
        Толщина: 'Tовщина',
        Коррозия: 'Корозія',
        'Шины и диски': 'Шини та диски',
        Остекление: 'Cкління',
        Комментарий: 'Коментар',
        Итог: 'Підсумок',
        'Проверка кузова авто': 'Перевірка кузова авто',
        'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Зверніть увагу! "Галочками" перевірений партнер відмітив деталі, які потребують додаткової уваги',
      },
    },
  },
};
