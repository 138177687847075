import { mapGetters } from 'vuex';
export default {
	name: 'Verifications',
	components: {
		Infotech: require('./Infotech/index.vue').default,
		Carvin: require('./Carvin/index.vue').default,
		Details: require('./Details/index.vue').default,
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
		}),
		verifications() {
			return this.preData.verifications;
		}
	}
};
