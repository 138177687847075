export default {
	messages: {
		uk: {
			'Аукцион': 'Аукціон',
			'AUTO.RIA – Продажа на аукционе': 'AUTO.RIA – Продаж на аукціоні',
			'На аукционе авто в Украине': 'На аукціоні авто в Україні',
			'Ваша заявка на дзвінки з AUTO.RIA прийнята': 'Ваша заявка на дзвінки з AUTO.RIA прийнята',
			'Вернуться к поиску': 'Вернуться к поиску',

		},
		ru: {
			'Аукцион': 'Аукцион',
			'На аукционе авто в Украине': 'На аукционе авто в Украине',
			'AUTO.RIA – Продажа на аукционе': 'AUTO.RIA – Продажа на аукционе',
			'Ваша заявка на дзвінки з AUTO.RIA прийнята': 'Ваша заявка на звонки с AUTO.RIA принята',
			'Вернуться к поиску': 'Повернутись до пошуку',

		}
	}
};
