import { mapGetters, mapActions } from 'vuex';

const { get, set } = require('../../../../helpers/cookie');

export default {
  data() {
    return {
      stars: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      textArea: '',
      reviewSend: false,
      selectedStar: 0,
    };
  },
  name: 'Evaluation',
  methods: {
    ...mapActions({
      sendAuctionFeedback: 'auction/sendAuctionFeedback',
    }),
    sendFeedback() {
      if (this.textArea.length !== 0) {
        const payload = {
          email: this.userData.userEmail,
          fio: this.userData.userName,
          phones: this.userData.userPhones.toString(),
          userId: this.userData.userId,
          ui: get('ui'),
          titleFeedback: 'Отзыв об Аукционе',
          textFeedback: `[Отзыв: ${Number(this.selectedStar)} ${Number(this.selectedStar) > 4 ? 'Удобно' : 'Не удобно'}] ${this.textArea}`,
          link: location.href,
        };

        this.sendAuctionFeedback(payload)
          .then(() => {
            set('auction_feedback', 1, { expires: 60 * 60 * 24 * 30 });
            this.reviewSend = true;
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      preData: 'auction/preData',
    }),
    isUserVoted() {
      return Boolean(_TARGET_ === 'client' && !get('auction_feedback'));
    },
  },
  watch: {
    selectedStar(val) {
      this._slonik({
        event_id: 753,
        screentype: Number(this.isDesktop ? 1 : 2),
        assessment: Number(val),
        owner_id: Number(this.userData.userId),
        proposal_id: Number(this.preData.id),
      });
    },
  },
  i18n: {
    messages: {
      uk: {
        'Оцените процесс участия в аукционе': 'Оцініть процес участі в аукціоні',
        'Напишите, что Вам понравилось, а что нет': 'Напишіть, що Вам сподобалось, а що ні',
        'Отправить отзыв': 'Відправити відгук',
        'Спасибо, мы все учтем и обязательно станем лучше': 'Дякуємо, ми все врахуємо й обов\'язково станемо краще',
        'Спасибо, мы стараемся для вас!': 'Дякуємо, ми стараємось для вас!',
      },
      ru: {
        'Оцените процесс участия в аукционе': 'Оцените процесс участия в аукционе',
        'Напишите, что Вам понравилось, а что нет': 'Напишите, что Вам понравилось, а что нет',
        'Отправить отзыв': 'Отправить отзыв',
        'Спасибо, мы все учтем и обязательно станем лучше': 'Спасибо, мы все учтем и обязательно станем лучше',
        'Спасибо, мы стараемся для вас!': 'Спасибо, мы стараемся для вас!',
      },
    },
  },
};
