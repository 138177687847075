import Vue from 'vue';
import _laxios from '../_laxios';

const {auction: {photos} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
        cursor: 0,
		photos: [],
	}, photos),
	actions: {
	},
	mutations: {
	    setCursor(state, index) {
	        return Vue.set(state, 'cursor', index);
        },
		photos(state, photos) {
	    	Vue.set(state, 'photos', photos);
		}
	},
	getters: {
		photos({ photos }) {
			return photos;
		},
	    cursor({ cursor }) {
	        return cursor;
        }
	}
};
