export default {
    name: 'Infotech',
    props: {
        data: Object,
    },
    computed: {
        labelsColors() {
            return this.data.labelsColors;
        },
        labelsText() {
            return this.data.labelsText;
        },
        reportDateInfo() {
            return this.data.reportDateInfo;
        }
    },
    i18n: {
        messages: {
            ru: {
                'Проверено AUTO.RIA': 'Проверено AUTO.RIA',
                'Двигатель': 'Двигатель',
                'Цвет': 'Цвет',
                'Розыск': 'Розыск',
                'Последняя операция': 'Последняя операция',
                'Количество владельцев': 'Количество владельцев',
                'Марка, модель, год': 'Марка, модель, год',
                'по реестрам МВД': 'по реестрам МВД',
                'Дата последней проверки': 'Дата последней проверки',
                'AUTO.RIA распознал гос.номер авто по фото': 'AUTO.RIA распознал гос.номер авто по фото',
                'AUTO.RIA сверил данные от продавца с открытыми реестрами МВД. Перед покупкой автомобиля, пожалуйста, сравните проверенный VIN-код с кодом, указанным на авто.': 'AUTO.RIA сверил данные от продавца с открытыми реестрами МВД. Перед покупкой автомобиля, пожалуйста, сравните проверенный VIN-код с кодом, указанным на авто.',
            },
            uk: {
                'Проверено AUTO.RIA': 'Перевірено AUTO.RIA',
                'Двигатель': 'Двигун',
                'Цвет': 'Колір',
                'Розыск': 'Розшук',
                'Последняя операция': 'Остання операція',
                'Количество владельцев': 'Кількість власників',
                'Марка, модель, год': 'Марка, модель, рік',
                'по реестрам МВД': 'за реєстрами МВС',
                'Дата последней проверки': 'Дата останньої перевірки',
                'AUTO.RIA распознал гос.номер авто по фото': 'AUTO.RIA розпізнав держ.номер авто по фото',
                'AUTO.RIA сверил данные от продавца с открытыми реестрами МВД. Перед покупкой автомобиля, пожалуйста, сравните проверенный VIN-код с кодом, указанным на авто.': 'AUTO.RIA звірив дані від продавця з відкритими реєстрами МВС. Перед покупкою автомобіля, будь ласка, порівняйте перевірений VIN-код з кодом, зазначеним на авто.',
            }
        }
    }
}
