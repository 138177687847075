import Vue from 'vue';
import _laxios from '../_laxios';

import photos from './photos';
import techCheck from './techCheck';
import bookmarks from './bookmarks';

module.exports = {
	state: Object.assign({
		id: 0,
		preData: {},
		stoInfo: {},
		bidsInfo: {},
		loggerData: {},
		viewers: 0,
		reloadDate: new Date(),
		popup: false,
		toggleTimer: false,
		showMegaPhoto: false,
	}, __INITIAL_STATE__.auction),
	getters: {
	    id({ id }) {
	        return id;
		},
		showMegaPhoto({ showMegaPhoto }) {
			return showMegaPhoto;
		},
		toggleTimer({ toggleTimer }) {
	    	return toggleTimer;
		},
		notepad({ notepad }) {
	    	return notepad;
		},
		popup({ popup }) {
	    	return popup;
		},
		payInfo({ preData: { payInfo } = {} } = {}) {
	    	return payInfo;
		},
		lotId({ preData: { auction: { id } = {} } = {} } = {}) {
	    	return id;
		},
		lastBid({ bidsInfo, preData: { auction: {
			prices: { start } = {},
			dates: { start: startDate } = {},
		} = {} } = {} } = {}) {
	    	let price = start;
	    	let isBid = false;
	    	let date = startDate;
	    	if (bidsInfo.length) {
	    		[ { price, date } ] = bidsInfo;
	    		isBid = true;
			}
	    	return { price, isBid, date };
		},
		viewers({ viewers }) {
	    	return viewers;
		},
		reloadDate({ reloadDate }) {
	    	return reloadDate;
		},
		preData({ preData }) {
	    	return preData;
		},
		loggerData({ loggerData }) {
	    	return loggerData;
		},
		altTitle({ preData }) {
			const { brand, model, year, city } = {} = preData;
			return `${brand.name} ${model.name} ${year} в ${city.locative}`;
		},
		labels({ preData: { labels = [] } = {} }) {
			return labels;
		},
		techCheck({ preData: { techCheck } }) {
			return techCheck;
		},
		stoInfo({stoInfo}) {
	    	return stoInfo;
		},
		bidsInfo({bidsInfo}) {
	    	return bidsInfo;
		},
		isAuctionActive({ preData: { auction } = {}, toggleTimer}) {
	    	const status = auction.status.id;
			if (!auction.dates.start) {
				return false;
			}
			const start = new Date(auction.dates.start).getTime();
			const end = new Date(auction.dates.end).getTime();
			const now = Date.now();
			return Boolean(now >= start && now <= end && status === 5);
		},
		isPreActive({ preData: { auction } = {}, toggleTimer}) {
			const status = auction.status.id;
			if (!auction.dates.start) { return true; }
			const start = new Date(auction.dates.start).getTime();
			return status < 6 && (start > Date.now());
		},
		soldOut({ preData: { auction: { dates, status} = {} } = {}, toggleTimer }) {
			const statusId = status.id;
	    	const start = new Date(dates.start).getTime();
	    	const end = new Date(dates.end).getTime();
			const now = Date.now();
			return Boolean(start < now && end < now && statusId >= 5);
		}
	},
	actions: {
	    id({ commit }, id) {
	        commit('id', id);
		},
		updateUser({ commit, getters }, data) {
			if (data?.onBoarding) {
				commit('user', data);
			}
			return _laxios
				.auctionUpdateUser
				.request({ responseType: 'json', data })
				.then((body) => commit('user', body));

		},
		getPreData({ commit, getters, rootGetters }, Cookie = {}) {
			const { 'lang/id': langId } = rootGetters;
	    	const { id, bidsInfo } = getters;
	    	return _laxios
				.auctionGetPreData
				.expandUrl({ id })
				.request({
					params: { langId },
					responseType: 'json',
					headers: { Cookie }
				})
				.then((payload) => {
					commit('photos/photos', payload.photos);
					commit('preData', payload);
					if (bidsInfo.bid) {
						payload.bids.bid = bidsInfo.bid;
					}
					commit('bidsInfo', payload.bids);
					return payload;
				});
		},
		getStoInfo({ commit, getters }) {
			const { id } = getters;

			if (getters.stoInfo && getters.stoInfo.schedulesInfo) {
				return new Promise(resolve => resolve(getters.stoInfo));
			} else {
				return _laxios
					.auctionGetStoInfo
					.expandUrl({ advId: id })
					.request()
					.then((payload) => {
						commit('stoInfo', payload);
						return payload;
					})
					.catch((error) => {
						console.error(error);
						commit('stoInfo', {});
						return {};
					});
			}
		},
		getBidsInfo({ commit, getters }, reload) {
	    	const { id } = getters;

			if (!reload && getters.bidsInfo && getters.bidsInfo.length > 0) {
				return new Promise(resolve => resolve(getters.bidsInfo));
			} else {
				return _laxios
					.auctionBidsInfo
					.expandUrl({ advId: id })
					.request()
					.then((payload) => {
						commit('bidsInfo', payload);
						if (reload) {
							commit('reloadDate', Date.now());
						}
						return payload;
					})
					.catch((error) => {
						console.error(error);
						commit('bidsInfo', {});
						return {};
					});
			}
		},
		sendAuctionFeedback(_, payload) {
	    	return _laxios
				.sendAuctionFeedback
				.request({ data: payload })
				.catch((error) => {
					console.error('ERROR:::sendAuctionFeedback', error.message);
				});
		},
		popup({ commit }, value) {
	    	commit('popup', value);
		}
	},
	mutations: {
	    id(state, id) {
	        Vue.set(state, 'id', id);
		},
		user(state, data) {
			Vue.set(state.preData, 'user', { ...state.preData.user, ...data });
		},
		popup(state, value) {
	    	Vue.set(state, 'popup', value);
		},
		showMegaPhoto(state, value) {
	    	Vue.set(state, 'popup', value);
	    	Vue.set(state, 'showMegaPhoto', value);
		},
		setEndDate(state, value) {
	    	if (new Date(value).getTime() > 1500000000000) {
				Vue.set(state.preData.auction.dates, 'end', value);
			}
		},
		toggleTimer(state) {
	    	Vue.set(state, 'toggleTimer', !state.toggleTimer);
		},
		preData(state, payload) {
	    	Vue.set(state, 'preData', payload);
		},
		stoInfo(state, payload) {
	    	Vue.set(state, 'stoInfo', payload);
		},
		bidsInfo(state, payload) {
			Vue.set(state, 'bidsInfo', payload);
		},
		viewers(state, count) {
	    	Vue.set(state, 'viewers', count);
		},
		reloadDate(state, date) {
			Vue.set(state, 'reloadDate', new Date(date));
		},
		loggerData(state, payload) {
			Vue.set(state, 'loggerData', payload);
		},
		setBidClosedAuction(state, payload) {
	    	Vue.set(state.bidsInfo, 'bid', payload);
		}
	},
	namespaced: true,
	modules: {
		bookmarks,
		photos,
		techCheck,
	}
};
