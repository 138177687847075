import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';

export default {
	name: 'Auction',
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			id: 'auction/id',
			activeBookmark: 'auction/bookmarks/active',
		}),
		active: {
			get() {
				return this.activeBookmark;
			},
			set(_value) {
				// мб тре зробити через сетер
			}
		},
		auction() {
			return this.preData.auction;
		},
		dates() {
			return this.auction.dates;
		},
		startDate() {
			return moment(this.dates.start).calendar();
		}
	},
	methods: {
		...mapMutations({
			setPopup: 'auction/popup',
			loggerData: 'auction/loggerData'
		}),
		...mapActions({
			addBookmarks: 'auction/bookmarks/add',
			deleteBookmarks: 'auction/bookmarks/delete'
		}),
		subscribe(event) {
			event.preventDefault();
			event.stopPropagation();
			if (this.activeBookmark) {
				this.deleteBookmarks();
			} else {
				this.addBookmarks();
			}
		},
	},
	components: {
		Timer: require('../common/Timer/index.vue').default,
	},
	i18n: {
		messages: {
			ru: {
				'Аукцион стартует': 'Аукцион стартует',
				'в ближайшее время': 'в ближайшее время',
				'Напомнить о старте аукциона': 'Напомнить о старте аукциона',
			},
			uk: {
				'Аукцион стартует': 'Аукціон стартує',
				'в ближайшее время': 'найближчім часом',
				'Напомнить о старте аукциона': 'Нагадати про старт аукціону',
			}
		}
	}
};
