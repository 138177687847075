import { mapGetters } from 'vuex';
export default {
    name: 'AuctionFinished',
    computed: {
        // ...mapGetters({
        //
        // })
    },
    i18n: {
        messages: {
            ru: {
                'Аукцион завершен': 'Аукцион завершен',
            },
            uk: {
                'Аукцион завершен': 'Аукціон завершено',
            }
        }
    }
}