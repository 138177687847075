import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'Header',
    mixins: [require('../../../../mixins/humanize')],
    computed: {
        ...mapGetters({
            preData: 'auction/preData',
            lastBid: 'auction/lastBid',
            soldOut: 'auction/soldOut',
            techCheck: 'auction/techCheck',
        }),
        city() {
            return this.preData.city;
        },
        state() {
            return this.preData.state;
        },
        isCenter() {
            return this.state.id === this.city.id;
        },
        prices() {
            return this.preData.auction.prices;
        },
        dates() {
            return this.preData.auction.dates;
        },
        price() {
            return this.lastBid.price;
        },
        text() {
            if (this.lastBid.isBid) {
                return this.$t('текущая ставка');
            } else {
                return this.$t('стартовая цена');
            }
        },
        soldOutPrice() {
            const { winningBid } = this.preData.auction;
            return winningBid.price || this.lastBid.price;
        },
        soldOutDate() {
            return moment(this.dates.end).fromNow();
        }
    },
    methods: {},
    i18n: {
        messages: {
            ru: {
                'тыс. км пробег': 'тыс. км пробег',
                'стартовая цена': 'стартовая цена',
                'текущая ставка': 'текущая ставка',
                'Цена авто формируется': 'Цена авто формируется',
            },
            uk: {
                'стартовая цена': 'стартова ціна',
                'тыс. км пробег': 'тис. км пробіг',
                'текущая ставка': 'поточна ставка',
                'Цена авто формируется': 'Ціна авто формується',
            }
        }
    }
}
