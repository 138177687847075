/* eslint-disable global-require,import/no-extraneous-dependencies */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import socket from './Socket';
import { clearURL } from '../../helpers/userNavigation';

const { get, set } = require('../../helpers/cookie');

const storeModule = require('../../store/auction');

const _seo = {
  2: {
    title: 'AUTO.RIA – Продажа на аукционе',
    description: 'Программа AUTO.RIA 24 поможет выкупить ваш автомобиль за короткий срок и вменяемые деньги. Всего три шага: заявка, встреча, сделка',
  },
  4: {
    title: 'AUTO.RIA – Продаж на аукціоні',
    description: 'Програма AUTO.RIA 24 допоможе викупити ваш автомобіль за короткий термін й гідні кошти. Всього три кроки: заявка, зустріч, угода',
  },
};
export default {
  name: 'Auction',
  i18n: require('./i18n').default,
  mixins: [require('../../mixins/slonDataLogger')],
  components: {
    Breadcrumbs: require('./Breadcrumbs/index.vue').default,
    Title: require('./Title/index.vue').default,
    Sidebar: require('./Sidebar/index.vue').default,
    Main: require('./Main/index.vue').default,
    sprite: require('./svgs/spirte.vue').default,
    OnBoarding: require('./OnBoarding/index.vue').default,
  },
  data() {
    return {
      viewLogger: true,
    };
  },
  init({ store, context, route }) {
    const { to: { params: { id } = {} } = {} } = {} = route;

    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }
    store.registerModule('auction', storeModule);
    store.dispatch('auction/id', Number(id));
    // так це потрібно саме тут, після того як є модуль аукціонів
    const { 'auction/preData': preData } = store.getters;
    return preData && preData.id
      ? preData
      : store
        .dispatch('auction/getPreData', cookies)
        .catch((error) => {
          const { status, data = {} } = error.response || {};
          if (status === 400) {
            return context.response.redirect(data.linkToRedirect);
          }
          console.error('GET [auction/getPreData] ERROR:', error.toString());
        });
  },
  computed: {
    ...mapGetters({
      id: 'auction/id',
      popup: 'auction/popup',
      preData: 'auction/preData',
      bidsInfo: 'auction/bidsInfo',
      loggerData: 'auction/loggerData',
      lastBid: 'auction/lastBid',
      isAuctionActive: 'auction/isAuctionActive',
      isPreActive: 'auction/isPreActive',
      viewers: 'auction/viewers',
    }),
    dateEnd() {
      return this.preData.auction.dates.end || '';
    },
    now() {
      return Date.now();
    },
    bidName() {
      const { ABTests: { bidName } = {} } = this.preData || {};
      return Number(bidName) || 0;
    },
    user() {
      const { user = {} } = this.preData || {};
      return user;
    },
  },
  watch: {
    loggerData(value) {
      if (Object.keys(value).length) {
        this.auctionLogger(751, value);
      }
    },
  },
  methods: {
    ...mapMutations({
      setViewers: 'auction/viewers',
      setBids: 'auction/bidsInfo',
      setReloadDate: 'auction/reloadDate',
      setEndDate: 'auction/setEndDate',
    }),
    ...mapActions({
      isBookmark: 'auction/isBookmark',
      // isActiveBookmark: 'auction/bookmarks/isActive'
    }),
    auctionLogger(eventId, extendData = {}) {
      try {
        const itemType = this.isPreActive ? 1 : (this.isAuctionActive ? 2 : 3);
        const duration = itemType < 3 && (this.dateEnd.toString().length > 0)
          ? Math.round(moment.duration(moment(this.dateEnd).subtract(Date.now())).asSeconds()) : 0;
        const defaultData = {
          event_id: eventId,
          proposal_id: this.id,
          price: this.lastBid.price,
          item_type: itemType,
          duration_sec: duration,
          view_count: this.viewers || 0,
          previous_url: encodeURIComponent(clearURL(document.referrer || '')),
          a_b_test_version: this.bidName,
        };
        if (Object.keys(extendData).length) Object.assign(defaultData, extendData);
        this._slonik(defaultData);
      } catch (e) {
        console.error('=== ERROR in auctionLogger ===', e.toString());
      }
    },
  },
  metaInfo() {
    const seo = _seo[this.langId];
    if (!this.preData || !Object.keys(this.preData).length) {
      return {
        title: seo.title,
        bodyAttrs: {
          class: this.popup ? 'modal-open page-overflow' : '',
        },
      };
    }
    return {
      title: `${this.$t('AUTO.RIA – Продажа на аукционе')} ${this.preData.brand.name} ${this.preData.model.name} ${this.preData.year} бу в ${this.preData.city.name}`,
      meta: [
        {
          name: 'description', content: `Продаю на AUTO.RIA ${this.preData.brand.name} ${this.preData.model.name} ${this.preData.year} бу в ${this.preData.city.name} ${this.$t('На аукционе авто в Украине')}`,
        },
        {
          name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW',
        },
      ],
      bodyAttrs: {
        class: this.popup ? 'modal-open page-overflow' : '',
      },
    };
  },
  mounted() {
    let key = get('auction_session_key');
    if (!key) {
      key = `${Math.round(Math.random() * 10000)}-${Date.now()}`;
      set('auction_session_key', key);
    }

    const initLogs = setTimeout(() => {
      if (this.viewLogger) {
        this.viewLogger = false;
        this.auctionLogger(749);
      }
    }, 8000);

    socket.init(this.id, key);
    socket.connect();
    socket.setHandler('info', (data) => {
      // safari
      this.setEndDate(data.endDate);
      if (data.userCount) {
        this.setBids(data.bids);
        this.setViewers(data.userCount);
        this.setReloadDate(data.date);
        if (this.viewLogger) {
          clearTimeout(initLogs);
          this.viewLogger = false;
          this.auctionLogger(749);
        }
      }
    });
  },
};
