import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
    name: 'BidsList',
    mixins: [require('../../../mixins/humanize')],
    props: {
        isFinished: Boolean,
    },
    data() {
        return {
            isGrey: true,
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            bids: 'auction/bidsInfo',
            preData: 'auction/preData',
            reloadDate: 'auction/reloadDate',
            soldOut: 'auction/soldOut',
        }),
        user() {
            return this.preData.user;
        },
        auction() {
            return this.preData.auction;
        },
        startPrice() {
            return this.auction.prices.start;
        },
        reloadAt() {
            const now = Date.now();
            const reload = new Date(this.reloadDate).getTime();
            if (reload + 60000 > now) {
                this.isGrey = false;
            }
            return moment(this.reloadDate).fromNow();
        }
    },
    methods: {
        ...mapActions({ getBidsInfo: 'auction/getBidsInfo' }),
        ...mapMutations({ loggerData: 'auction/loggerData' }),
        reloadBids() {
            this.loggerData({ click_action: 16 });
            this.isLoading = true;
            this.getBidsInfo(true)
                .catch((error) => {
                    console.error('reload bids error =>', error);
                    return null;
                })
                .then(() => this.isLoading = false);
        },
        calculateDiff(date) {
            return moment(date).fromNow();
        }
    },
    i18n: {
        messages: {
            ru: {
                'стартовая цена': 'стартовая цена',
                'Последние ставки': 'Последние ставки',
                'Аукцион завершен': 'Аукцион завершен',
                'обновлено': 'обновлено',
                'принята': 'принята',
                'Дивитись ще': 'Смотреть еще',
            },
            uk: {
                'стартовая цена': 'стартова ціна',
                'Последние ставки': 'Останні ставки',
                'Аукцион завершен': 'Аукціон завершено',
                'обновлено': 'оновлено',
                'принята': 'прийнята',
                'Дивитись ще': 'Дивитись ще',
            }
        }
    }
}
