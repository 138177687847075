import { mapGetters } from 'vuex';
export default {
	name: 'Statistic',
	props: [ 'bid' ],
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			viewers: 'auction/viewers',
			bids: 'auction/bidsInfo',
		}),
		version() {
			return this.preData.version;
		},
		bidsCount() {
			if (this.version) {
				return this.bids.count;
			}
			return this.bids.length || 0;
		},
	},
	methods: {
		scrollToBids(event) {
			window.scrollTo({
				top: this.bid.offsetTop,
				behavior: 'smooth'
			});
		}
	},
	i18n: {
		messages: {
			ru: {
				'ставка': 'ставка | ставка | ставки | ставок',
				'смотрят': 'смотрят',
			},
			uk: {
				'ставка': 'ставка | ставка | ставки | ставок',
				'смотрят': 'дивляться',
			}
		},
	},
};
