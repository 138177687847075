import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'Autotest',
	components: {
		checksSVG: require('../../svgs/checks.vue').default,
		WaitingFor: require('./WaitingFor/index.vue').default,
		Report: require('./Report/index.vue').default,
	},
	computed: {
		...mapGetters({
			techCheck: 'auction/techCheck',
			report: 'auction/techCheck/report',
		}),
	},
	methods: {
		...mapActions({
			fetchReport: 'auction/techCheck/fetchReport',
		})
	},
	mounted() {
		const {id, hash} = this.techCheck;
		if (id && hash) {
			this.fetchReport({id, hash});
		}
	}
};
