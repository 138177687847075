<template>
  <svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       style="position: absolute; width: 0; height: 0; overflow: hidden;">
    <symbol id="checked_closed" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6667 6H12.6667V4.66666C12.6667 2.09344 10.5732 0 8 0C5.42675 0 3.33334 2.09344 3.33334 4.66666V6H2.33334C2.14909 6 2 6.14909 2 6.33334V14.6667C2 15.402 2.59797 16 3.33334 16H12.6667C13.402 16 14 15.402 14 14.6667V6.33334C14 6.14909 13.8509 6 13.6667 6ZM8.99806 12.9632C9.00847 13.0573 8.97822 13.1517 8.91506 13.2223C8.85191 13.293 8.76141 13.3333 8.66669 13.3333H7.33334C7.23863 13.3333 7.14812 13.293 7.08497 13.2223C7.02181 13.1517 6.99153 13.0573 7.00197 12.9632L7.21225 11.0723C6.87078 10.8239 6.66669 10.431 6.66669 10C6.66669 9.26466 7.26466 8.66666 8.00003 8.66666C8.73541 8.66666 9.33337 9.26462 9.33337 10C9.33337 10.431 9.12928 10.8239 8.78781 11.0723L8.99806 12.9632ZM10.6667 6H5.33334V4.66666C5.33334 3.19628 6.52962 2 8 2C9.47038 2 10.6667 3.19628 10.6667 4.66666V6Z"
            fill="#9B9B9B"/>
    </symbol>
    <symbol id="car_body_check" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m2.0533 9.3612-0.52514 0.52514c-0.25375 0.25376-0.35208 0.62396-0.25766 0.97026l2.2927 8.4064c0.11866 0.4351 0.51382 0.7369 0.96477 0.7369h16.682c0.5095 0 0.9376-0.3831 0.9938-0.8895l0.5503-4.9521c0.1368-1.2316-0.165-2.472-0.8523-3.503-0.4067-0.61-0.9362-1.1283-1.5546-1.5219l-7.837-4.9872c-1.2107-0.77041-2.588-1.2402-4.0171-1.3701l-7.3468-0.66789 0.90662 7.2529zm-1.0529-0.36127-0.1793 0.1793c-0.5075 0.5075-0.70416 1.2481-0.51531 1.9405l2.2927 8.4065c0.23731 0.8701 1.0276 1.4737 1.9295 1.4737h16.682c1.0191 0 1.8752-0.7662 1.9877-1.7791l0.5503-4.9521c0.1628-1.4654-0.1963-2.9413-1.0142-4.1681-0.4838-0.72579-1.1139-1.3426-1.8498-1.8108l-7.8369-4.9872c-1.3452-0.85602-2.8756-1.378-4.4634-1.5223l-7.3468-0.66789c-0.63563-0.05778-1.162 0.48661-1.0828 1.1199l0.84596 6.7676z" clip-rule="evenodd" fill-rule="evenodd"/>
      <path d="m3.1516 4.1518 0.48908 3.9126 11.359 0.8573v-0.27295c0-0.35567-0.1889-0.68457-0.4961-0.86378l-3.2401-1.89c-1.0497-0.61235-2.2126-1.0058-3.4185-1.1565l-4.6936-0.5867zm-0.99228 0.12404 0.48908 3.9126c0.05902 0.47215 0.44255 0.83732 0.91702 0.87313l11.359 0.8573c0.5802 0.04379 1.0752-0.41523 1.0752-0.99716v-0.27295c0-0.71133-0.3778-1.3691-0.9922-1.7276l-3.2401-1.89c-1.1664-0.68039-2.4585-1.1175-3.7984-1.285l-4.6936-0.5867c-0.64707-0.08088-1.1972 0.46924-1.1163 1.1163z" clip-rule="evenodd" fill-rule="evenodd"/>
      <path d="m17 7h-1v2h1v-2zm-1-1c-0.5523 0-1 0.44772-1 1v2c0 0.55228 0.4477 1 1 1h1c0.5523 0 1-0.44772 1-1v-2c0-0.55228-0.4477-1-1-1h-1z" clip-rule="evenodd" fill-rule="evenodd"/>
    </symbol>
    <symbol id="car_diagnostic" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1H5V6H7V1ZM5 0C4.44772 0 4 0.447715 4 1V6C4 6.55228 4.44772 7 5 7H7C7.55228 7 8 6.55228 8 6V1C8 0.447715 7.55228 0 7 0H5Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 18H5V23H7V18ZM5 17C4.44772 17 4 17.4477 4 18V23C4 23.5523 4.44772 24 5 24H7C7.55228 24 8 23.5523 8 23V18C8 17.4477 7.55228 17 7 17H5Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 1H17V6H19V1ZM17 0C16.4477 0 16 0.447715 16 1V6C16 6.55228 16.4477 7 17 7H19C19.5523 7 20 6.55228 20 6V1C20 0.447715 19.5523 0 19 0H17Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 18H17V23H19V18ZM17 17C16.4477 17 16 17.4477 16 18V23C16 23.5523 16.4477 24 17 24H19C19.5523 24 20 23.5523 20 23V18C20 17.4477 19.5523 17 19 17H17Z"/>
        <path d="M8 3H10V4H8V3Z"/>
        <path d="M14 3H16V4H14V3Z"/>
        <path d="M12.5 5L12.5 18L11.5 18L11.5 5L12.5 5Z"/>
        <path d="M8 20H16V21H8V20Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 3H11V4H13V3ZM10 2V5H14V2H10Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 19H11V20H13V19ZM10 18V21H14V18H10Z"/>
    </symbol>
    <symbol id="car_engine_inspection" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8 23C11.866 23 15 19.866 15 16C15 12.134 11.866 9 8 9C4.13401 9 1 12.134 1 16C1 19.866 4.13401 23 8 23ZM8 24C12.4183 24 16 20.4183 16 16C16 11.5817 12.4183 8 8 8C3.58172 8 0 11.5817 0 16C0 20.4183 3.58172 24 8 24Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8 19C9.65685 19 11 17.6569 11 16C11 14.3431 9.65685 13 8 13C6.34315 13 5 14.3431 5 16C5 17.6569 6.34315 19 8 19ZM8 20C10.2091 20 12 18.2091 12 16C12 13.7909 10.2091 12 8 12C5.79086 12 4 13.7909 4 16C4 18.2091 5.79086 20 8 20Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.2198 3.68555L8.70312 8.02996C9.1843 8.07192 9.65327 8.15648 10.1062 8.27984L14.9993 5.04664C14.9991 5.03092 14.999 5.01517 14.999 4.99941C14.999 4.53916 15.0767 4.09707 15.2198 3.68555ZM18.999 8.99941C19.4592 8.99941 19.9013 8.92167 20.3128 8.77862L15.9684 15.2953C15.9265 14.8141 15.8419 14.3451 15.7185 13.8921L18.9517 8.99913C18.9675 8.99932 18.9832 8.99941 18.999 8.99941Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19 9C21.2091 9 23 7.20914 23 5C23 2.79086 21.2091 1 19 1C16.7909 1 15 2.79086 15 5C15 7.20914 16.7909 9 19 9ZM19 10C21.7614 10 24 7.76142 24 5C24 2.23858 21.7614 0 19 0C16.2386 0 14 2.23858 14 5C14 7.76142 16.2386 10 19 10Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6ZM19 7C20.1046 7 21 6.10457 21 5C21 3.89543 20.1046 3 19 3C17.8954 3 17 3.89543 17 5C17 6.10457 17.8954 7 19 7Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.2461 2.93465L9.74614 4.93465L9.25 4.06641L12.75 2.06641L13.2461 2.93465Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.082 17.2227L19.082 14.2227L19.9141 14.7774L17.9141 17.7774L17.082 17.2227Z"/>
    </symbol>
    <symbol id="car_computer_diagnostics" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 5H4V15H20V5ZM4 4C3.44772 4 3 4.44772 3 5V16H21V5C21 4.44772 20.5523 4 20 4H4Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.56619 16L1.76619 19H22.2338L20.4338 16H3.56619ZM21 15H3L0.9087 18.4855C0.508785 19.152 0.988898 20 1.76619 20H22.2338C23.0111 20 23.4912 19.152 23.0913 18.4855L21 15Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 9.41421V12H10V11H14V12H15V9.41421L13.5858 8H10.4142L9 9.41421ZM10 7L8 9H7.5C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10H8V13H11V12H13V13H16V10H16.5C16.7761 10 17 9.77614 17 9.5C17 9.22386 16.7761 9 16.5 9H16L14 7H10Z"/>
    </symbol>
    <symbol id="car_checking_light" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 13C22 11.2446 21.5379 9.52019 20.6603 8C19.7826 6.47981 18.5202 5.21743 17 4.33975C15.4798 3.46206 13.7554 3 12 3C10.2446 3 8.52019 3.46206 7 4.33975C5.47981 5.21743 4.21743 6.47981 3.33974 8C2.46206 9.52019 2 11.2446 2 13C2 14.7554 2.46206 16.4798 3.33975 18L2.47372 18.5C1.50827 16.8278 0.999999 14.9309 1 13C1 11.0691 1.50827 9.17221 2.47372 7.5C3.43917 5.82779 4.82779 4.43917 6.5 3.47372C8.17221 2.50827 10.0691 2 12 2C13.9309 2 15.8278 2.50827 17.5 3.47372C19.1722 4.43917 20.5608 5.82779 21.5263 7.5C22.4917 9.17221 23 11.0691 23 13C23 14.9309 22.4917 16.8278 21.5263 18.5L20.6603 18C21.5379 16.4798 22 14.7554 22 13Z"/>
        <path d="M11.5 5H12.5V7H11.5V5Z"/>
        <path d="M5.99023 7.69678L6.69734 6.98967L8.11155 8.40388L7.40445 9.11099L5.99023 7.69678Z"/>
        <path d="M17.3037 6.98975L18.0108 7.69685L16.5966 9.11107L15.8895 8.40396L17.3037 6.98975Z"/>
        <path d="M4 13.5L4 12.5L6 12.5L6 13.5L4 13.5Z"/>
        <path d="M18 13.5L18 12.5L20 12.5L20 13.5L18 13.5Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 19H7C6.44772 19 6 19.4477 6 20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20C18 19.4477 17.5523 19 17 19ZM7 18C5.89543 18 5 18.8954 5 20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18H7Z"/>
        <path d="M14.4746 9.81812L15.1817 10.5252L13.2597 12.4472L12.5526 11.7401L14.4746 9.81812Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14ZM12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15Z"/>
    </symbol>
    <symbol id="car_checking_salon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.9248 6.92936C3.14553 8.25233 2 9.93308 2 11.9999C2 14.0667 3.14553 15.7475 4.9248 17.0704C6.71119 18.3987 9.03645 19.281 11.089 19.7153C11.5096 19.8043 12 19.477 12 18.8571V5.14271C12 4.52283 11.5096 4.19553 11.089 4.28453C9.03645 4.71883 6.71119 5.60107 4.9248 6.92936ZM13 5.14271C13 3.98392 12.0156 3.06632 10.882 3.30619C6.58099 4.21624 1 7.02007 1 11.9999C1 16.9797 6.58099 19.7835 10.882 20.6936C12.0156 20.9335 13 20.0159 13 18.8571V5.14271Z"/>
        <path d="M15 6L23 8V9L15 7V6Z"/>
        <path d="M15 11L23 13V14L15 12V11Z"/>
        <path d="M15 16L23 18V19L15 17V16Z"/>
    </symbol>
    <symbol id="car_test_drive" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5398 10.5659C17.0424 10.699 17.4764 10.8139 18.1299 10.8974C18.3567 10.9264 18.5485 10.8436 18.6677 10.7185C18.7809 10.5999 18.8302 10.4465 18.7845 10.2669C18.0135 7.23864 15.2671 5 12.0004 5C8.73369 5 5.98731 7.23864 5.21636 10.2669C5.17065 10.4465 5.21999 10.5999 5.33309 10.7185C5.45238 10.8436 5.6441 10.9264 5.87095 10.8974C6.52444 10.8139 6.9584 10.699 7.46105 10.5659C7.69059 10.5052 7.93446 10.4406 8.2201 10.3735C9.10925 10.1647 10.1779 10 12.0004 10C13.8229 10 14.8916 10.1647 15.7807 10.3735C16.0664 10.4406 16.3102 10.5052 16.5398 10.5659ZM18.0031 11.8893C19.0988 12.0293 20.0261 11.0907 19.7536 10.0202C18.8725 6.55952 15.7354 4 12.0004 4C8.26546 4 5.1283 6.55952 4.24727 10.0202C3.97476 11.0907 4.90206 12.0293 5.99772 11.8893C6.71848 11.7972 7.24202 11.6589 7.77297 11.5186C8.74231 11.2626 9.73636 11 12.0004 11C14.2645 11 15.2585 11.2626 16.2279 11.5186C16.7588 11.6589 17.2824 11.7972 18.0031 11.8893Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99965 15H6.83128C6.49533 15 6.24285 15.1616 6.12353 15.3339C6.06622 15.4167 6.04439 15.4934 6.04219 15.5565C6.04017 15.6145 6.0529 15.6974 6.12393 15.8068C6.87163 16.9581 7.94849 17.8757 9.21933 18.4264C9.40003 18.5047 9.55368 18.4757 9.69765 18.3598C9.86104 18.2284 9.99965 17.9812 9.99965 17.6732V16C9.99965 15.4477 9.55194 15 8.99965 15ZM8.99965 14C10.1042 14 10.9997 14.8954 10.9997 16V17.6732C10.9997 18.876 9.92537 19.8221 8.82176 19.344C7.36865 18.7143 6.13907 17.6661 5.28526 16.3514C4.57352 15.2555 5.52449 14 6.83128 14H8.99965Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9996 15H17.1677C17.5037 15 17.7561 15.1616 17.8755 15.334C17.9328 15.4168 17.9546 15.4934 17.9568 15.5566C17.9589 15.6146 17.9461 15.6976 17.8751 15.8069C17.1273 16.9582 16.0506 17.8758 14.7799 18.4264C14.5992 18.5047 14.4456 18.4757 14.3016 18.3599C14.1382 18.2284 13.9996 17.9812 13.9996 17.6733V16C13.9996 15.4477 14.4473 15 14.9996 15ZM14.9996 14C13.895 14 12.9996 14.8954 12.9996 16V17.6733C12.9996 18.876 14.0739 19.8222 15.1775 19.344C16.6305 18.7144 17.8599 17.6663 18.7137 16.3516C19.4255 15.2556 18.4746 14 17.1677 14H14.9996Z"/>
    </symbol>
  </svg>
</template>
<script>
export default {
  name: 'checksSVG'
}
</script>
