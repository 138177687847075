import { mapMutations } from 'vuex';

export default {
    name: 'CheckingLight',
    props: {
        data: Object,
    },
    data() {
        return {
            openBlock: false
        }
    },
    methods: {
        ...mapMutations({loggerData: 'auction/loggerData'})
    },
    i18n: {
        messages: {
            ru: {
                'если смотреть на автомобиль': 'если смотреть на автомобиль',
                'Другие замечания': 'Другие замечания',
                'Регулятор наклона угла света': 'Регулятор наклона угла света',
                'работает': 'работает',
                'не работает': 'не работает',
            },
            uk: {
                'если смотреть на автомобиль': 'якщо дивитися на автомобіль',
                'Другие замечания': 'Інші зауваження',
                'Регулятор наклона угла света': 'Регулятор нахилу кута світла',
                'работает': 'працює',
                'не работает': 'не працює',
            }
        }
    }
}
