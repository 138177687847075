import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
	name: 'Subscribe',
	computed: {
	    ...mapGetters({
			preData: 'auction/preData',
			id: 'auction/id',
			active: 'auction/bookmarks/active',
			login: 'auction/bookmarks/login',
			success: 'auction/bookmarks/success',
		})
	},
	methods: {
		...mapMutations({
			setLoginPopup: 'auction/bookmarks/login',
			setSuccessPopup: 'auction/bookmarks/success',
			setPopup: 'auction/popup',
		}),
		close() {
			this.setLoginPopup(false);
			this.setSuccessPopup(false);
			this.setPopup(false);
		}
	},
	i18n: {
		messages: {
			ru: {
				'Аукцион стартует': 'Аукцион стартует',
				'Напомнить о старте аукциона': 'Напомнить о старте аукциона',
				'Войдите или зарегистрируйтесь': 'Войдите или зарегистрируйтесь',
				'чтобы получить напоминание': 'чтобы получить напоминание',
				'о старте аукциона': 'о старте аукциона',
				'Войти на AUTO.RIA': 'Войти на AUTO.RIA',
				'Зарегистрироваться': 'Зарегистрироваться',
				'Спасибо': 'Спасибо',
				'Вы подписались на аукцион и получите уведомление, когда начнутся торги': 'Вы подписались на аукцион и получите уведомление, когда начнутся торги',
				'Закрыть': 'Закрыть',
			},
			uk: {
				'Аукцион стартует': 'Аукціон стартує',
				'Напомнить о старте аукциона': 'Нагадати про старт аукціону',
				'Войдите или зарегистрируйтесь': 'Увійдіть або зареєструйтеся',
				'чтобы получить напоминание': 'щоб отримати нагадування',
				'о старте аукциона': 'про старт аукціона',
				'Войти на AUTO.RIA': 'Увійти на AUTO.RIA',
				'Зарегистрироваться': 'Зареєструватися',
				'Спасибо': 'Дякуємо',
				'Вы подписались на аукцион и получите уведомление, когда начнутся торги': 'Ви підписалися на аукціон і отримаєте повідомлення, коли почнуться торги',
				'Закрыть': 'Закрити',
			}
		}
	}
};
