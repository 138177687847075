import { mapGetters, mapMutations } from 'vuex';
export default {
	name: 'Prices',
	mixins: [require('../../../../../mixins/humanize')],
	computed: {
		...mapGetters({
			payInfo: 'auction/payInfo',
			preData: 'auction/preData',
		}),
		version() {
			return this.preData.auction.version || 0;
		},
		style() {
			if (this.version) {
				return { border: 'none' };
			}
			return '';
		}
	},
	methods: {
		...mapMutations({ loggerData: 'auction/loggerData' }),
	},
	i18n: {
		messages: {
			ru: {
				'Аукционный сбор': 'Аукционный сбор',
				'оплачивается при выигрышной ставке': 'оплачивается при выигрышной ставке',
				'аукционный сбор при покупке авто': 'аукционный сбор при покупке авто',
			},
			uk: {
				'Аукционный сбор': 'Аукціонний збір',
				'оплачивается при выигрышной ставке': 'оплачується при виграшній ставці',
				'аукционный сбор при покупке авто': 'аукціонний збір при купівлі авто',
			}
		}
	}
};
