import { mapMutations } from 'vuex';

export default {
	name: 'SwitchBox',
	props: {
		data: Object,
		icon: String,
		actionIdDefault: String,
	},
	data() {
		return {
			showAll: false,
			openBlock: false
		};
	},
	computed: {
		elements() {
			return this.data.elements || {};
		},
		moreBtnText() {
			return this.$t(this.showAll ? 'Скрыть' : 'Смотреть все');
		},
		actionId() {
			return Number(this.actionIdDefault);
		},
	},
	methods: {
		...mapMutations({loggerData: 'auction/loggerData'})
	},
	i18n: {
		messages: {
			ru: {
				'Смотреть все': 'Смотреть все',
				'Скрыть': 'Скрыть',
                'Другие замечания': 'Другие замечания',
                'Все исправно': 'Все исправно',
			},
			uk: {
				'Смотреть все': 'Дивитись все',
				'Скрыть': 'Сховати',
                'Другие замечания': 'Інші зауваження',
                'Все исправно': 'Все справно',
			}
		}
	}
};
