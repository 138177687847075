import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
	name: 'Price',
	mixins: [require('../../../mixins/humanize')],
	data() {
		return {
			price: 0,
		};
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			bidsInfo: 'auction/bidsInfo',
			lastBid: 'auction/lastBid',
			techCheck: 'auction/techCheck',
			isAuctionActive: 'auction/isAuctionActive',
			soldOut: 'auction/soldOut',
			activeBookmark: 'auction/bookmarks/active',
		}),
		auction() {
			return this.preData.auction;
		},
		soldOutPrice() {
			const { winningBid } = this.auction;
			return winningBid.price || this.lastBid.price;
		},
		soldOutDate() {
			const { winningBid } = this.auction;
			return moment(winningBid.date || this.lastBid.date).fromNow();
		},
	},
	methods: {
		...mapMutations({
			setPopup: 'auction/popup',
			loggerData: 'auction/loggerData'
		}),
		...mapActions({
			addBookmarks: 'auction/bookmarks/add',
			deleteBookmarks: 'auction/bookmarks/delete',
		}),
		subscribe(event) {
			event.preventDefault();
			event.stopPropagation();
			if (this.activeBookmark) {
				this.deleteBookmarks();
			} else {
				this.addBookmarks();
			}
		},
	},
	i18n: {
		messages: {
			ru: {
				'Добавить объявление в избранное': 'Добавить объявление в избранное',
				'текущая ставка': 'текущая ставка',
				'стартовая цена': 'стартовая цена',
				'Смотреть отчет СТО проверки': 'Смотреть отчет СТО проверки',
				'Цена авто формируется': 'Цена авто формируется',
				'средняя цена похожего авто': 'средняя цена похожего авто',
			},
			uk: {
				'Добавить объявление в избранное': 'Додати оголошення в обране',
				'текущая ставка': 'поточна ставка',
				'стартовая цена': 'стартова ціна',
				'Смотреть отчет СТО проверки': 'Дивитися звіт СТО перевірки',
				'Цена авто формируется': 'Ціна авто формується',
				'средняя цена похожего авто': 'середня ціна схожого авто',
			}
		}
	}
};
