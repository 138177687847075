import Vue from 'vue';
import VueYoutube from 'vue-youtube';
import { mapGetters, mapMutations } from 'vuex';
Vue.use(VueYoutube);

export default {
	name: 'ViewZone',
	data() {
		return {
			touch: {x: 0},
			animation: 'animation',
		};
	},
	components: {
		MegaPhoto: require('./../MegaPhoto/index.vue').default,
	},
	computed: {
		...mapGetters({
			preData: 'auction/preData',
			title: 'auction/altTitle',
			cursor: 'auction/photos/cursor',
			photos: 'auction/photos/photos',
			soldOut: 'auction/soldOut',
			showMegaPhoto: 'auction/showMegaPhoto',
		}),
		current() {
			return this.photos[this.cursor] || {
				link: {
					f: 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg'
				}
			};
		},
		isVideo() {
			return this.current.type === 'VIDEO';
		},
		hasPhotos() {
			return Boolean(this.photos.length);
		},
	},
	mounted() {
		window.addEventListener('keyup', this.keyupEvent);
	},
	methods: {
		...mapMutations({
			setCursor: 'auction/photos/setCursor',
			setPopup: 'auction/popup',
			setShowMegaPhoto: 'auction/showMegaPhoto',
			loggerData: 'auction/loggerData'
		}),
		playing() {
			this.loggerData({ click_action: 21 });
		},
		touchstart(event) {
			const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
			this.touch.x = x;
		},
		touchend(event) {
			const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
			if (this.touch.x - x > 50) {
				this.next();
			}
			if (x - this.touch.x > 50) {
				this.prev();
			}
		},
		keyupEvent(event) {
			const {code} = event;

			if (['ArrowLeft', 'KeyA'].includes(code)){
				this.prev();
			} else if (['ArrowRight', 'KeyD'].includes(code)) {
				this.next();
			}
		},
		prev() {
			let c = this.cursor - 1;
			if (c < 0) {
				c = this.photos.length - 1;
			}
			this.setCursor(c);
		},
		next() {
			let c = this.cursor + 1;
			if (c >= this.photos.length) {
				c = 0;
			}
			this.setCursor(c);
		},
		openMegaphoto() {
			this.setShowMegaPhoto(true);
		}
	},
	i18n: {
		messages: {
			ru: {
				'на клавиатуре для просмотра следующего фото': 'на клавиатуре для просмотра следующего фото',
				'на клавиатуре для просмотра предыдущего фото': 'на клавиатуре для просмотра предыдущего фото',
				'Используйте стрелку': 'Используйте стрелку',
				'Авто продано с аукциона': 'Аукцион завершен',
			},
			uk: {
				'на клавиатуре для просмотра следующего фото': 'на клавіатурі для перегляду наступного фото',
				'на клавиатуре для просмотра предыдущего фото': 'на клавіатурі для перегляду попереднього фото',
				'Используйте стрелку': 'Використовуйте стрілку',
				'Авто продано с аукциона': 'Аукціон завершився',
			}
		}
	}
};
