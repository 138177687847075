import {mapGetters} from 'vuex';

export default {
    name: 'Sidebar',
    components: {
        Header: require('./Header/index.vue').default,
        Seller: require('./../Seller/index.vue').default,
        Auction: require('./../Auction/index.vue').default,
        AuctionFinished: require('./../AuctionFinished/index.vue').default,
        auctionsActive: require('./../ActiveAuctions/index.vue').default,
        BidsList: require('../BidsList/index.vue').default,
    },
    computed: {
        ...mapGetters({
            preData: 'auction/preData',
            isAuctionActive: 'auction/isAuctionActive',
            isPreActive: 'auction/isPreActive',
            soldOut: 'auction/soldOut',
        }),
        auction() {
            return this.preData.auction;
        },
        status() {
            return this.auction.status.id;
        },
        variant() {
            return this.auction.variant || 0;
        },
    }
}
