const BACKSPACE = 'Backspace';

export default {
    name: 'Code',
    props: {
        phone: String,
        wrongCode: Boolean,
    },
    data() {
        return {
            segments: {
                1: '',
                2: '',
                3: '',
                4: '',
            },
            full: '',
        }
    },
    computed: {
        code() {
            const s = this.segments;
            const code = `${s[1]}${s[2]}${s[3]}${s[4]}`;
            this.$emit('code', code);
            return code;
        }
    },
    methods: {
        focus(segment) {
            const segments = this.segments;
            if (segments.hasOwnProperty(segment)) {
                document.getElementById(String(segment)).focus();
            }
        },
        keyup(event) {
            const { target: { id }, key } = event;
            const segments = this.segments;
            const segment = Number(id);
            const code = Number(key);

            if(!Number.isNaN(code)) {
                segments[id] = key;
                this.focus(segment + 1);
            } else if(key === BACKSPACE) {
                this.focus(segment - 1)
            }
            this.full = this.code;
        },
        paste(event) {
            const data = event.clipboardData.getData('text');
            const focusSegment = data.length > 4 ? 4 : data.length;
            const segments = this.segments;

            this.focus(focusSegment);
            for (let i = 1; i <= focusSegment;  i++) {
                segments[i] = data[i - 1];
            }
        },
        retry() {
            this.$emit('retry');
        },
    },
    i18n: {
        messages: {
            ru: {
                'В течение 3 мин ждите код подтверждения в SMS на номер': 'В течение 3 мин ждите код подтверждения в SMS на номер',
                'Не верный код': 'Не верный код',
                'Отправить еще раз': 'Отправить еще раз',
            },
            uk: {
                'В течение 3 мин ждите код подтверждения в SMS на номер': 'Протягом 3 хв очікуйте код підтвердження в SMS на номер',
                'Не верный код': 'Не вірний код',
                'Отправить еще раз': 'Відправити ще раз',
            }
        }
    }
}