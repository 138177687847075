import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Gallery',
    data() {
        return {
            show: false,
        };
    },
    computed: {
        ...mapGetters({
            report: 'auction/techCheck/report',
        }),
        photos() {
            return this.report.photos;
        }
    },
    methods: {
        ...mapMutations({
            setPopup: 'auction/popup',
            setShowMegaPhoto: 'auction/showMegaPhoto',
            loggerData: 'auction/loggerData'
        }),
        open() {
            this.setPopup(true);
            this.show = true;
        },
        close() {
            this.setPopup(false);
            this.show = false;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Все фото': 'Всі фото',
                'Фотоотчет повреждений от СТО': 'Фотозвіт пошкоджень від СТО',

            },
            ru: {
                'Все фото': 'Все фото',
                'Фотоотчет повреждений от СТО': 'Фотоотчет повреждений от СТО',
            }
        }
    }
}
