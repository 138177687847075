import socket from '../../../../Socket';

const CONFIRM_PHONE_ACTION = 'confirm';
const SEND_CODE_ACTION = 'code';

export default {
    name: 'Confirmation',
    mixins: [require('../../../../../../mixins/humanize')],
    props: {
        close: Function,
        bid: Function,
        price: Number,
        title: String,
    },
    data() {
        return {
            phone: '',
            state: 1,
            innerPhone: '',
            confirmationCode: '',
            wrongCode: false,
        }
    },
    mounted() {
        socket.setHandler(CONFIRM_PHONE_ACTION, ({ phone, status }) => {
            if (status === 'ok') {
               this.innerPhone = phone;
               this.state = 2;
            }
        });
        socket.setHandler(SEND_CODE_ACTION, ({ phone, status }) => {
            if (status === 'ok') {
                this.state = 3;
                this.bid();
            } else if (status === 'wrong') {
                this.wrongCode = true;
            }
        });
    },
    components: {
        Code: require('./Code/index.vue').default,
    },
    methods: {
        enterCode(value) {
            this.confirmationCode = value;
        },
        confirmPhone() {
            socket.message({
                action: CONFIRM_PHONE_ACTION,
                phone: this.phone,
            });
        },
        sendCode(...elements) {
            if (this.confirmationCode.length < 4) {
                this.wrongCode = true;
            } else {
                this.wrongCode = false;
                socket.message({
                    action: SEND_CODE_ACTION,
                    code: this.confirmationCode,
                    phone: this.phone,
                });
            }
        }
    },
    i18n: {
        messages: {
            ru: {
                'Введите ваш телефон': 'Введите ваш телефон',
                'Следуйте за ходом торгов и выигрывайте': 'Следуйте за ходом торгов и выигрывайте',
                'Подтвердить ставку': 'Подтвердить ставку',
                'Подтвердить через': 'Подтвердить через',
                'Политикой приватности': 'Политикой приватности',
                'Ваши персональные данные будут обработаны и защищены согласно': 'Ваши персональные данные будут обработаны и защищены согласно',
                'Подтвердите ставку, чтобы она была сохранена': 'Подтвердите ставку, чтобы она была сохранена',
                'Вы предлагаете за': 'Вы предлагаете за',
                'Предложите цену': 'Предложите цену',
                'Отменить': 'Отменить',
                'Ставка принята': 'Ставка принята',
            },
            uk: {
                'Введите ваш телефон': 'Введіть ваш телефон',
                'Следуйте за ходом торгов и выигрывайте': 'Слідкуйте за перебігом торгів та вигравайте',
                'Подтвердить ставку': 'Підтвердити ставку',
                'Подтвердить через': 'Підтвердити через',
                'Политикой приватности': 'Політики приватності',
                'Ваши персональные данные будут обработаны и защищены согласно': 'Ваші персональні дані будуть оброблені та захищені згідно до',
                'Подтвердите ставку, чтобы она была сохранена': 'Підтвердіть ставку, щоб вона була збережена',
                'Вы предлагаете за': 'Ви пропонуєте за',
                'Предложите цену': 'Запропонувати ціну',
                'Отменить': 'Відмінити',
                'Ставка принята': 'Ставку прийнято',
            },
        }
    }
}