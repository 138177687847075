// центр перевірок
const INSPECTION_CENTER_STATION_ID = 650;
// виїзна перевірка
const ON_SITE_VERIFYING_STATION_ID = 707;

module.exports = {
  AUTO_RIA_STATIONS: [INSPECTION_CENTER_STATION_ID, ON_SITE_VERIFYING_STATION_ID],
  INSPECTION_CENTER_STATION_ID,
  ON_SITE_VERIFYING_STATION_ID,
  ELECTRIC_FUEL_ID: 6,
  EXAMPLE_TECH_REPORT_ID: 55999,
  EXAMPLE_ELECTRIC_TECH_REPORT_ID: 242924,
  TECH_CHECK_MIN_PRICE: 3500,
  TECHNICAL_CHECKS_MY: 44,
  TECHNICAL_CHECKS_ALL: 3,
  COMPUTER_DIAGNOSTICS_ID: 4,
  CHECK_ENGINE: 7,
};
