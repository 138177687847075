import { mapMutations } from 'vuex';

export default {
    name: 'RunningDiagnostics',
    props: {
        data: Object,
    },
    data() {
        return {
            openBlock: false
        }
    },
    methods: {
        ...mapMutations({loggerData: 'auction/loggerData'})
    },
    i18n: {
        messages: {
            ru: {
                'Диагностика ходовой части': 'Диагностика ходовой части',
                'Левая': 'Левая',
                'Правая': 'Правая',
                'Без замечания': 'Без замечания',
                'Другие замечания': 'Другие замечания',
                'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания',
            },
            uk: {
                'Диагностика ходовой части': 'Діагностика ходової частини',
                'Левая': 'Ліва',
                'Правая': 'Права',
                'Без замечания': 'Без зауваження',
                'Другие замечания': 'Інші зауваження',
                'Обратите внимание! "Галочками" проверенный партнер отметил детали, которые требуют дополнительного внимания': 'Зверніть увагу! "Галочками" перевірений партнер відмітив деталі, які потребують додаткової уваги',
            }
        }
    }
};
